import { createContext } from "react";
import { AlertColor } from "@mui/material";

interface IAlertContext {
  setAlert: (txt: string, typ: AlertColor) => void;
  text: string;
  type: AlertColor | undefined;
}

const AlertContext = createContext<IAlertContext>({} as IAlertContext);

export default AlertContext;
