import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { StorageKeys } from "../utils/StorageUtils";
import { useAuth } from "../context/AuthContext/hooks";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem(StorageKeys.isLoggedIn) !== "true") {
      navigate("/login", { replace: true, state: { from: location } });
    }
  }, [auth]);

  return children;
};

export default RequireAuth;
