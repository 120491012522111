/* eslint-disable */
import Box from "@mui/material/Box";
import * as React from "react";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext/hooks";

const styles = {
  logoutContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 1,
    ":hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  logoutIcon: { color: theme.palette.text.primary },
  logout: { pl: 2, color: theme.palette.text.primary },
};

const Logout = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const onLogoutClick = () => {
    auth.signout(() => {
      navigate("/login");
    });
  };

  return (
    <Button sx={styles.logoutContainer} onClick={onLogoutClick}>
      <LogoutIcon sx={styles.logoutIcon} />
      <Box sx={styles.logout}>Logout</Box>
    </Button>
  );
};

export default Logout;
