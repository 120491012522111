import * as React from "react";
import CommonScreenComponent, {
  States,
} from "../CommonScreenComponent/index_backup";

const CancledOrders = () => {
  return <CommonScreenComponent states={States.CANCLED} />;
};

export default CancledOrders;
