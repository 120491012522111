import * as React from "react";
import theme from "../../theme";
import ScreenLayout from "../../components/ScreenLayout";
import SubLayout from "../../components/ScreenLayout/SubLayout";
import AlertPopup from "../../components/AlertPopup";
import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Box, Button } from "@mui/material";
import { OrderProcessingRepo } from "../../repository/OrderProcessingRepo";
import { useAlert } from "../../context/AlertContext/hooks";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext/hooks";
import DatePickerCustom from "../../components/DatePickerCustom";
import {
  DateUtil,
  allNull,
  includesNull,
  includesTrue,
} from "../../utils/DateUtils";
import CustomTable from "../../components/CustomTable";
import { CustomCheckbox } from "../../components/CustomCheckbox";

export type IpendingOrders = {
  dispatchDate: number;
  logisticPartner: string;
  orderDate: number;
  packedState: boolean;
  sellerApobId: string;
  sellerId: number;
  sellerName: string;
  shipmentId: number;
  shipmentItems: any;
};

const styles = {
  container: {},
  download: {
    width: "30%",
    mt: 5,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
  },
  uploadFile: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  fileIcon: { pr: 2, color: theme.palette.secondary.dark },
  clearIcon: { color: theme.palette.secondary.dark },
  uploadingFilesConatiner: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    width: "90%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 1,
    borderRadius: "8px",
    height: "10%",
    marginTop: 3,
  },
  marginHorizontal: {
    marginLeft: 1,
    marginRight: 1,
  },
};

export enum States {
  PENDING = "PENDING",
  PACKED = "PACKED",
  CANCLED = "CANCLED",
}

export const createPackedStatus = (
  mockPendingOrdersValue: any[]
): Record<string, boolean> => {
  const packedObject: Record<string, boolean> = {};
  mockPendingOrdersValue.map((item) => {
    packedObject[item.shipmentId] = item.packedState;
  });
  console.log("createPackedStatus pckt", {
    packedObject,
    mockPendingOrdersValue,
  });
  return packedObject;
};

const CommonScreenComponent = ({
  states,
}: {
  packedState?: Record<string, boolean>;
  parentColumns?: ColumnDef<IpendingOrders, any>[];
  states: States;
}) => {
  const [dispatchDateRange, setDispatchDateRange] = React.useState([
    null,
    null,
  ]);
  const [orderDateRange, setOrderDateRange] = React.useState<any[]>([
    null,
    null,
  ]);
  const [dispatchStartDate, dispatchEndDate] = dispatchDateRange;
  const [orderStartDate, orderEndDate] = orderDateRange;
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState<IpendingOrders[]>(() => []);
  const [rowSelection, setRowSelection] = React.useState({});
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const auth = useAuth();
  const [packedState, setPackedState] = React.useState<Record<string, boolean>>(
    {}
  );
  const isPending = states == States.PENDING;
  const isCancled = states == States.CANCLED;

  const rerender = React.useReducer(() => ({}), {})[1];
  const [columnVisibility, setColumnVisibility] = React.useState({});

  const updatePackedStatus = (
    packedStateParam: Record<string, boolean>,
    shipmentId: string
  ) => {
    packedStateParam[shipmentId] = !packedStateParam[shipmentId];
    return packedStateParam;
  };

  const updatePackeState = (val: React.FormEvent<HTMLInputElement>) => {
    const value: Record<string, boolean> = packedState;
    Object.keys(packedState).forEach((item) => {
      //@ts-ignore
      value[item] = val?.target?.checked;
    });
    setPackedState(value);
  };

  const columnHelper = createColumnHelper<IpendingOrders>();
  const columns = React.useMemo(() => {
    return [
      {
        id: isPending ? "select" : "downloadInvoice",
        header: ({ table }: { table: any }) => {
          return (
            <Box>
              <CustomCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
              <span>
                {isPending || isCancled
                  ? "Select for Order Download"
                  : "Download Invoice"}
              </span>
            </Box>
          );
        },

        cell: (info: any) => {
          const { row } = info;
          return (
            <Box>
              <CustomCheckbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </Box>
          );
        },
      },
      columnHelper.accessor("logisticPartner", {
        header: () => <span>Logistic Partner</span>,
        cell: (info) => {
          return info.getValue();
        },
      }),
      columnHelper.accessor("dispatchDate", {
        header: () => "Dispatch Date",
        cell: (info) => (
          <div style={{}}>
            {info.renderValue()?.toString().slice(0, 10)}
            <div>{info.renderValue()?.toString().slice(10)}</div>
          </div>
        ),
      }),
      columnHelper.accessor("shipmentId", {
        header: () => "Order ID",
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("orderDate", {
        header: "Order Date",
        cell: (info) => (
          <div style={{}}>
            {info.renderValue()?.toString().slice(0, 10)}
            <div>{info.renderValue()?.toString().slice(10)}</div>
          </div>
        ),
        enableHiding: true,
      }),
      {
        id: "packedState",
        header: (info: { table: { getRowModel: () => any } }) => {
          console.log("header", { info: info.table.getRowModel() });
          return (
            <Box>
              <CustomCheckbox
                {...{
                  onChange: (val: React.FormEvent<HTMLInputElement>) => {
                    updatePackeState(val);
                    rerender();
                  },
                }}
              />
              <span>Select for Pack confirm</span>
            </Box>
          );
        },
        cell: (info: { row: { getValue: (arg0: string) => string } }) => {
          return (
            <Box>
              <CustomCheckbox
                {...{
                  checked: packedState[String(info.row.getValue("shipmentId"))],
                  onChange: (ch) => {
                    rerender();
                    setPackedState(
                      updatePackedStatus(
                        packedState,
                        info.row.getValue("shipmentId")
                      )
                    );
                  },
                }}
              />
            </Box>
          );
        },
      },
    ];
  }, [isPending, packedState]);

  const fetchPendingOrder = (options?: any) => {
    setIsLoading(true);
    OrderProcessingRepo.fetchPendingOrder({
      states: isPending
        ? ["PLACED", "FETCHED"]
        : isCancled
        ? ["CANCELLED"]
        : ["PACKED"],
      ...(!!dispatchStartDate && !!dispatchEndDate
        ? {
            scheduleDeliveryDate: {
              from: DateUtil.formatDate(String(dispatchStartDate), true),
              to: DateUtil.formatDate(String(dispatchEndDate), true),
            },
          }
        : {}),
      ...(!!orderStartDate && !!orderEndDate
        ? {
            orderDate: {
              from: DateUtil.formatDate(String(orderStartDate), true),
              to: DateUtil.formatDate(String(orderEndDate), true),
            },
          }
        : {}),
      ...options,
      orderCategory: isPending ? ["NON_RX"] : undefined,
    })
      .then(async (res) => {
        if (res?.status == 200) {
          const content = await res.json();
          const localData: IpendingOrders[] = content.shipments.map(
            (item: IpendingOrders): IpendingOrders => {
              return {
                ...item,
                packedState: false,
                //@ts-ignore
                dispatchDate: DateUtil.formatDate(item.dispatchDate),
                //@ts-ignore
                orderDate: DateUtil.formatDate(item.orderDate),
              };
            }
          );
          setData(localData);
        } else {
          if (res.status > 200 && res.status < 299) {
            const errorRes = await res.json();
            setData([]);
            setAlert(
              `${String(errorRes?.code)} ${String(errorRes?.message)}`,
              "info"
            );
          } else if (res.status == 401) {
            auth.signout(() => {
              navigate("/login");
            });
            return;
          } else {
            const errorRes = await res.json();
            setAlert(
              `${String(errorRes?.code)} ${String(errorRes?.message)}`,
              "error"
            );
          }
        }
      })
      .catch((e) => {
        console.log({ e });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (!includesNull(orderDateRange) || allNull(orderDateRange)) {
      fetchPendingOrder();
    }
  }, [orderDateRange]);

  React.useEffect(() => {
    if (!includesNull(dispatchDateRange) || allNull(dispatchDateRange)) {
      fetchPendingOrder();
    }
  }, [dispatchDateRange]);

  React.useEffect(() => {
    if (data.length > 0) {
      setPackedState(createPackedStatus(data));
    }
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      columnVisibility,
    },
    enableRowSelection: true,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableHiding: true,
    debugColumns: true,
    debugTable: true,
  });

  const downloadPendingOrder = () => {
    const orderIds: number[] = table
      .getSelectedRowModel()
      .flatRows.map((selectedRowObject) => {
        return selectedRowObject.getValue("shipmentId");
      });
    OrderProcessingRepo.downloadPendingOrder(orderIds).then(async (res) => {
      const link = document.createElement("a");
      const content = await res.text();
      console.log({ content });
      const file = new Blob([content], { type: "text/plain" });
      link.href = URL.createObjectURL(file);
      link.download = "pending-orders.csv";
      link.click();
      URL.revokeObjectURL(link.href);
    });
  };

  const downloadInvoice = () => {
    const orderIds: number[] = table
      .getSelectedRowModel()
      .flatRows.map((selectedRowObject) => {
        return selectedRowObject.getValue("shipmentId");
      });
    // @ts-ignore
    OrderProcessingRepo.downloadInvoice(orderIds).then((resAllPromise: any) => {
      Promise.all(resAllPromise).then(async (values) => {
        const downloadUrls: any[] = [];

        for (const res of values) {
          if (res.status === 200) {
            const resJson = await res.json();

            const urls = resJson.shippingLabels;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            downloadUrls.push(...urls);
          } else if (res.status === 401) {
            auth.signout(() => {
              navigate("/login");
            });
            return;
          } else {
            const errorRes = await res.json();
            setAlert(
              `${String(errorRes.code)} ${String(errorRes.message)}`,
              "error"
            );
          }
        }

        // Triggering Downloads Sequentially
        let index = 0;
        const openNextUrl = () => {
          if (index >= downloadUrls.length) return;

          const url = downloadUrls[index];
          const link = document.createElement("a");
          link.href = url;
          link.download = `invoice_${index + 1}.pdf`;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          index += 1;
          setTimeout(openNextUrl, 1000); // Delay to prevent pop-up blocking
        };

        openNextUrl();
      });

      return;
    });
  };

  React.useEffect(() => {
    fetchPendingOrder();
    if (!isPending) {
      table.getAllColumns().map((col) => {
        if (col.id == "packedState") col.toggleVisibility(false);
      });
    }
  }, []);

  return (
    <ScreenLayout containerStyle={styles.container}>
      <AlertPopup />
      <SubLayout containerStyle={styles.subContainer}>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            flex: 1,
            margin: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <DatePickerCustom
              endDate={dispatchEndDate}
              startDate={dispatchStartDate}
              setDateRange={(date: any[]) => {
                setDispatchDateRange(date);
              }}
              key={1}
              title={"Dispatch Date Filter:"}
            />
            <DatePickerCustom
              endDate={orderEndDate}
              startDate={orderStartDate}
              setDateRange={(date: any[]) => {
                setOrderDateRange(date);
              }}
              key={2}
              title={"Order Date Filter:"}
            />
          </Box>
          <Box>
            <Button
              disabled={
                table
                  .getSelectedRowModel()
                  .flatRows.map((selectedRowObject) => {
                    console.log({ selectedRowObject });
                    return selectedRowObject.getValue("shipmentId");
                  }).length < 1
              }
              sx={styles.marginHorizontal}
              variant="contained"
              onClick={() => {
                if (isPending || isCancled) {
                  downloadPendingOrder();
                } else {
                  downloadInvoice();
                }
              }}
            >
              {isPending || isCancled ? "Download orders" : "Download Invoice"}
            </Button>
            {isPending && !!packedState ? (
              <Button
                disabled={!includesTrue(Object.values(packedState))}
                sx={styles.marginHorizontal}
                variant="contained"
                onClick={() => {
                  const orderIds = Object.keys(packedState)
                    .map((item) => {
                      if (packedState[item]) {
                        return Number(item);
                      }
                      return null;
                    })
                    .filter((item) => item !== null);
                  //@ts-ignore
                  OrderProcessingRepo.updateOrderStatusToPacked(orderIds).then(
                    async (res) => {
                      if (res?.status == 200) {
                        const content = await res.json();
                        console.log({ content });
                        setAlert(
                          `${String(content.statusCode)} ${String(
                            content.message
                          )}`,
                          "success"
                        );
                        fetchPendingOrder();
                      } else {
                        if (res.status > 200 && res.status < 299) {
                          const errorRes = await res.json();
                          setAlert(
                            `${String(errorRes.code)} ${String(
                              errorRes.message
                            )}`,
                            "info"
                          );
                        } else if (res.status == 401) {
                          auth.signout(() => {
                            navigate("/login");
                          });
                          return;
                        } else {
                          const errorRes = await res.json();
                          setAlert(
                            `${String(errorRes.code)} ${String(
                              errorRes.message
                            )}`,
                            "error"
                          );
                        }
                      }
                    }
                  );
                }}
              >
                Update Packed Status
              </Button>
            ) : null}
          </Box>
        </Box>
        <CustomTable isLoading={isLoading} table={table} />
      </SubLayout>
    </ScreenLayout>
  );
};

export default CommonScreenComponent;
