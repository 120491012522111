import { IBuildConfig } from "./interface";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const env = process.env;

console.log({ env });

export const BuildConfig: IBuildConfig = {
  appVersion: env.REACT_APP_BUILD_VERSION || "",
  baseUrl: env.REACT_APP_API_BASE_URL || "",
};
