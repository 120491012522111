export enum StorageKeys {
  isLoggedIn = "isLoggedIn",
  sessionId = "sessionId",
  sellerApobId = "sellerApobId",
  sellerName = "sellerName",
}
interface IStorageUtils {
  getISLoggedIn: () => string;
  getItem: (key: StorageKeys) => string | null;
  removeItem: (val: StorageKeys) => void;
  setISLoggedIn: (val: string) => void;
  setItem: (key: StorageKeys, value: string) => void;
}

const StorageUtils = (): IStorageUtils => {
  return {
    getISLoggedIn: () => {
      return localStorage.getItem(StorageKeys.isLoggedIn) || "";
    },
    setISLoggedIn: (val: string) => {
      localStorage.setItem(StorageKeys.isLoggedIn, val);
    },
    removeItem: (key: StorageKeys) => {
      localStorage.removeItem(key);
    },
    setItem: (key: StorageKeys, value: string) => {
      localStorage.setItem(key, value);
    },
    getItem: (key: StorageKeys) => {
      return localStorage.getItem(key);
    },
  };
};

export default StorageUtils;
