import { ConfigHelper } from "../utils/ConfigHelper";
import StorageUtils, { StorageKeys } from "../utils/StorageUtils";

// import axios from "axios";

export interface ICallBackRes {
  data: ArrayBuffer | undefined;
  isSucess: boolean;
  status?: number;
}

class OrderProcessingRepo {
  public fetchPendingOrder = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    const sellerID = sellerApobId?.substring(0, 3);
    console.log({ sessionID, sellerID, sellerApobId, filterParam });
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    return fetch(ConfigHelper.getBaseUrl() + "/shipments/filter", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sellerId: String(sellerID),
        sellerApobId: String(sellerApobId),
        filter: {
          orderDate: {
            from: "",
            to: "",
            // from: DateUtil.formatDate(String(new Date)),
            // to: DateUtil.formatDate(String(new Date)),
          },
          // pageNumber: 1,
          // pageSize: 10,
          ...filterParam,
        },
      }),
    });
  };

  public downloadPendingOrder = async (
    orderIds: (number | null)[]
  ): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    const sellerID = sellerApobId?.substring(0, 3);
    console.log({ sessionID, sellerID, sellerApobId });
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    return fetch(ConfigHelper.getBaseUrl() + "/shipments/download", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sellerId: String(sellerID),
        sellerApobId: String(sellerApobId),
        filter: {
          states: [],
          orderDate: {
            from: "",
            to: "",
          },
          orderIds: orderIds,
        },
      }),
    });
  };

  //@ts-ignore
  public downloadInvoice = async (orderId: string[]) => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    // const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    // const sellerID = sellerApobId?.substring(0, 3);
    // console.log({ sessionID, sellerID, sellerApobId });
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    //@ts-ignore
    const dummpyCode = await Promise.resolve(1);
    console.log({ dummpyCode });
    return orderId.map((id) =>
      fetch(ConfigHelper.getBaseUrl() + `/shipments/pdf/download/${id}`, {
        method: "GET",
        headers: myHeaders,
      })
    );
  };

  public updateOrderStatusToPacked = async (
    orderIds: number[]
  ): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    const sellerID = sellerApobId?.substring(0, 3);
    console.log({ sessionID, sellerID, sellerApobId });
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        orderIds: orderIds,
      }),
    };

    return fetch(
      ConfigHelper.getBaseUrl() + "/shipments/order-status",
      requestOptions
    );
  };

  // public fetchInvoiceAndLabel = (
  //   id: string,
  //   Callback: (res: ICallBackRes) => void
  // ) => {
  //   const sessionID = StorageUtils().getItem(StorageKeys.sessionId);

  //   try {
  //     const xhr = new XMLHttpRequest();
  //     xhr.open(
  //       "GET",
  //       ConfigHelper.getBaseUrl() + `/shipments/pdf/download/${id}`,
  //       true
  //     );
  //     xhr.setRequestHeader("session-id", String(sessionID));
  //     xhr.responseType = "arraybuffer";
  //     xhr.onload = function (e) {
  //       console.log({ e, status: this.status });
  //       Callback({ data: this.response, isSucess: true, status: this.status });
  //     };
  //     xhr.send();
  //   } catch (e) {
  //     Callback({ data: undefined, isSucess: false });
  //   }
  // };

  // public uploadUpdatedOrders = async (csvFiles: File) => {
  //   const myHeaders = new Headers();
  //   const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
  //   console.log({ sessionID });
  //   myHeaders.append("session-id", String(sessionID));
  //   const formData = new FormData();
  //   formData.append("file", csvFiles, "sample.csv");
  //   const requestOptions = {
  //     method: "POST",
  //     body: formData,
  //     headers: myHeaders,
  //   };
  //   return fetch(
  //     ConfigHelper.getBaseUrl() + "/shipments/upload/",
  //     requestOptions
  //   );
  // };
}

const orderProcessingRepo = new OrderProcessingRepo();
export { orderProcessingRepo as OrderProcessingRepo };
