import React, { useReducer } from "react";
import AnimContext from "./index";

const initialValues = {
  isSideBarOpen: true,
  isShowSideBarContents: true,
};

interface IAction {
  payload: Record<any, any>;
  type: string;
}

export interface IAnimState {
  isShowSideBarContents: boolean;
  isSideBarOpen: boolean;
}

enum AnimEnum {
  TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
  SHOW_SIDEBAR_CONTENTS = "SHOW_SIDEBAR_CONTENTS",
}

const reducer = (state: IAnimState, action: IAction) => {
  switch (action.type) {
    case AnimEnum.TOGGLE_SIDEBAR:
      console.log({ action });
      return {
        ...state,
        isSideBarOpen: action.payload?.isSideBarOpen,
      };
    case AnimEnum.SHOW_SIDEBAR_CONTENTS:
      console.log({ action });
      return {
        ...state,
        isShowSideBarContents: action.payload?.isShowSideBarContents,
      };
    default:
      return state;
  }
};

const AnimProvider = ({ children }: { children: React.ReactNode }) => {
  const [animState, dispatch] = useReducer(reducer, initialValues);

  const toggleSideBar = () => {
    dispatch({
      type: AnimEnum.TOGGLE_SIDEBAR,
      payload: { isSideBarOpen: !animState.isSideBarOpen },
    });
  };

  const sideBarTimeOut = (isSideBarOpen: boolean) => {
    console.log("Timeout Start", isSideBarOpen);
    setTimeout(() => {
      dispatch({
        type: AnimEnum.SHOW_SIDEBAR_CONTENTS,
        payload: { isShowSideBarContents: !isSideBarOpen },
      });
    }, 420);
  };

  const value = { animState, toggleSideBar, sideBarTimeOut };

  return <AnimContext.Provider value={value}>{children}</AnimContext.Provider>;
};
export default AnimProvider;
