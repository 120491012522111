import { format } from "date-fns";

class DateUtils {
  public formatDate = (date: string | number, isISO?: boolean): string => {
    if (isISO) {
      const localDate = new Date(date);
      return new Date(
        new Date(date).setHours(
          localDate.getHours() + 5,
          localDate.getMinutes() + 30
        )
      ).toISOString();
    } else {
      return format(new Date(date), "yyyy-MM-dd p");
    }
  };
}

const dateUtil = new DateUtils();
export { dateUtil as DateUtil };

export function includesNull(arr: any[]) {
  return arr.some((el: any) => el == null);
}

export function allNull(arr: any[]) {
  return arr.every((el: any) => el == null);
}

export function includesTrue(arr: any[]) {
  return arr.some((el: any) => el == true);
}
