import React, { useState } from "react";
import AlertContext from "./index";
import { AlertColor } from "@mui/material";

const ALERT_TIME = 5000;

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [text, setText] = useState<string>("");
  const [type, setType] = useState<AlertColor | undefined>();

  const setAlert = (txt: string, typ: AlertColor) => {
    setText(txt);
    setType(typ);

    setTimeout(() => {
      setText("");
      setType(undefined);
    }, ALERT_TIME);
  };
  const value = {
    text,
    type,
    setAlert,
  };
  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

export default AlertProvider;
