import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import theme from "../../theme";
import { SxProps } from "@mui/material";

const styles = {
  container: {
    display: "flex",
    flex: 1,
    // width: "94%",
    height: window.innerHeight * 0.9,
    backgroundColor: theme.palette.background.default,
    borderRadius: 5,
    // justifyContent: "center",
    // alignItems: "center",
    flexDirection: "column",
    marginRight: 3,
  },
};

interface IScreenLayout {
  children: ReactNode;
  containerStyle?: SxProps;
}

const ScreenLayout = (props: IScreenLayout) => {
  const { children, containerStyle = {} } = props;
  // @ts-ignore
  return <Box sx={[styles.container, containerStyle]}>{children}</Box>;
};

export default ScreenLayout;
