import React from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { flexRender } from "@tanstack/react-table";

const NoDataComponent = () => {
  return (
    <Box
      component={"div"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box component={"h3"}>
        No pending orders as per the filters, kindly update the filters or
        Refresh
      </Box>
    </Box>
  );
};

const CustomTable = ({
  isLoading,
  table,
}: {
  isLoading: boolean;
  table: any;
}) => {
  const rerender = React.useReducer(() => ({}), {})[1];

  React.useEffect(() => {
    rerender();
  }, [table]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={100} sx={{}} />
        </Box>
      ) : (
        <Table stickyHeader sx={{ zIndex: 0 }}>
          <TableHead>
            {table
              .getHeaderGroups()
              .map(
                (headerGroup: {
                  headers: any[];
                  id: React.Key | null | undefined;
                }) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(
                      (header: {
                        column: {
                          columnDef: {
                            header:
                              | string
                              | number
                              | boolean
                              | React.ComponentType<any>
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined;
                          };
                        };
                        getContext: () => any;
                        id: React.Key | null | undefined;
                        isPlaceholder: any;
                      }) => (
                        <TableCell key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                )
              )}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map(
                (row: {
                  getVisibleCells: () => {
                    column: {
                      columnDef: {
                        cell:
                          | string
                          | number
                          | boolean
                          | React.ReactFragment
                          | React.ReactPortal
                          | React.ComponentType<any>
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | null
                          | undefined;
                      };
                    };
                    getContext: () => any;
                    id: React.Key | null | undefined;
                  }[];
                  id: React.Key | null | undefined;
                }) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map(
                      (cell: {
                        column: {
                          columnDef: {
                            cell:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactFragment
                              | React.ReactPortal
                              | React.ComponentType<any>
                              | null
                              | undefined;
                          };
                        };
                        getContext: () => any;
                        id: React.Key | null | undefined;
                      }) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                )
              )
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
      )}
      {table.getRowModel().rows.length < 1 && !isLoading ? (
        <NoDataComponent />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CustomTable;
