import { Box, Button } from "@mui/material";
import React from "react";
//@ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import TimePickerCustom from "../TimePickerCustom";

const ExampleCustomInput = React.forwardRef(
  ({ value, onClick }: { onClick: () => void; value: string }) => (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{ ...(!!value && { paddingRight: 4 }) }}
    >
      {!!value ? value : "from - to"}
    </Button>
  )
);

const DatePickerCustom = ({
  startDate,
  endDate,
  setDateRange,
  title,
}: {
  endDate: string | null;
  setDateRange: (date: any[]) => void;
  startDate: string | null;
  title: string;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Box>
        <Box
          sx={{ fontSize: 12, marginRight: 1, fontWeight: "bold" }}
          component={"p"}
        >
          {title}
        </Box>
        {!!startDate && !!endDate ? (
          <Box
            sx={{
              fontSize: 12,
              marginRight: 1,
              fontWeight: "bold",
              marginTop: 3,
            }}
            component={"p"}
          >
            Time Filter
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginRight: 2,
          flexDirection: "column",
        }}
      >
        <DatePicker
          selected={startDate}
          // calendarContainer={MyContainer}
          closeOnScroll={true}
          //@ts-ignore
          customInput={<ExampleCustomInput />}
          startDate={startDate}
          endDate={endDate}
          onChange={setDateRange}
          isClearable={true}
          selectsRange={true}
          // inline
          monthsShown={2}
          maxDate={new Date()}
          // showTimeSelect
          placeholderText="select date"
          shouldCloseOnSelect={true}
          // form="external-form"
          // showYearDropdown
          // withPortal
          //  wrapperClassName='date_picker_style'
        />
        {!!startDate && !!endDate ? (
          <TimePickerCustom updateDate={setDateRange} />
        ) : null}
      </Box>
    </Box>
  );
};

export default DatePickerCustom;
