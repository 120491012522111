import { ConfigHelper } from "../utils/ConfigHelper";
import StorageUtils, { StorageKeys } from "../utils/StorageUtils";

class PrescriptionRepository {
  public fetchPendingOrder = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    const sellerID = sellerApobId?.substring(0, 3);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    return fetch(ConfigHelper.getBaseUrl() + "/shipments/filter", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        sellerId: String(sellerID),
        sellerApobId: String(sellerApobId),
        filter: {
          orderDate: {
            from: "",
            to: "",
            // from: DateUtil.formatDate(String(new Date)),
            // to: DateUtil.formatDate(String(new Date)),
          },
          orderCategory: ["RX"],
          ...filterParam,
        },
      }),
    });
  };

  public fetchHistoryOrder = async (
    userId: string,
    filterParam: any
  ): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    const sellerID = sellerApobId?.substring(0, 3);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    const today = new Date();
    const oneYearAgoFromToday = new Date();
    oneYearAgoFromToday.setFullYear(today.getFullYear() - 1);
    const formatDate = (date: Date): string => {
      return date.toISOString();
    };
    return fetch(ConfigHelper.getBaseUrl() + "/shipments/filter", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        userId: userId,
        sellerId: String(sellerID),
        sellerApobId: String(sellerApobId),
        filter: {
          orderDate: {
            from: formatDate(oneYearAgoFromToday),
            to: formatDate(today),
          },
          orderCategory: ["RX"],
          crmState: "VERIFIED",
          states: ["DELIVERED"],
          ...filterParam,
        },
      }),
    });
  };

  public fetchPrescription = async (filterParam: string): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID = StorageUtils().getItem(StorageKeys.sessionId);
    const sellerApobId = StorageUtils().getItem(
      StorageKeys.sellerApobId
    ) as string;
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("session-id", String(sessionID));
    myHeaders.append("x-client-id", "seller-panel");

    return fetch(
      `https://marketplace.preprod.fkhealthplus.com/sellers/v1/prescription/?sellerApobId=${sellerApobId}&orderId=${filterParam}`,
      {
        method: "GET",
        headers: {
          ...myHeaders,
          "CLIENT-ID": "fkh-dev",
          "REQUEST-ID": "fdsah",
        },
      }
    );
  };

  public approvePrescription = async (orderId: any): Promise<any> => {
    const myHeaders = new Headers();
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("CLIENT-ID", "fkh-dev");
    const bodyData = JSON.stringify({
      sellerApobId: String(sellerApobId),
      orderId: orderId,
      status: "APPROVED",
    });
    return fetch(ConfigHelper.getBaseUrl() + "/order/status/update", {
      method: "POST",
      headers: myHeaders,
      body: bodyData,
    });
  };

  public cancelPrescription = async (orderId: any): Promise<any> => {
    const myHeaders = new Headers();
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("CLIENT-ID", "fkh-dev");
    myHeaders.append("x-client-id", "VIADUCT");
    const bodyData = JSON.stringify({
      orderId: orderId,
      sellerApobId: String(sellerApobId),
      reason: "PRESCRIPTION_DECLINE",
      note: "note",
    });

    return fetch(ConfigHelper.getBaseUrl() + "/order/cancel", {
      method: "POST",
      headers: myHeaders,
      body: bodyData,
    });
  };
}

const prescriptionRepository = new PrescriptionRepository();
export { prescriptionRepository as PrescriptionRepository };
