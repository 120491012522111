import React from "react";

import Box from "@mui/material/Box";
import { Avatar, Popover } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Logout from "../Logout";
import Image from "../Image/image";
import logo from "../../assets/logo-webp.webp";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../../theme";
import { useAnim } from "../../context/AnimationContext/hooks";
import StorageUtils, { StorageKeys } from "../../utils/StorageUtils";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: window.innerHeight * 0.1,
  },
  menuContainer: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 2,
    marginLeft: 2,
    minWidth: "0px",
    ":hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  menu: {
    color: theme.palette.primary.dark,
  },
  userAvatar: { bgcolor: deepOrange[500], height: 30, width: 30 },
  chip: { pt: 3, pb: 3, marginRight: 3, pl: 1, pr: 1 },
  logoutContainer: {
    width: window.innerWidth * 0.2,
    height: window.innerHeight * 0.1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    paddingLeft: 3,
    paddingRight: 3,
    // borderRadius: 100,
  },
  logoContainer: {
    width: window.innerWidth * 0.12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

function Header() {
  const anim = useAnim();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onMenuClick = () => {
    anim.sideBarTimeOut(Boolean(anim.animState?.isSideBarOpen));
    anim.toggleSideBar();
  };
  const onLogoClick = () => {
    navigate("/");
  };
  const sellerName = StorageUtils().getItem(StorageKeys.sellerName);
  const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
  const firstLetter = sellerName ? sellerName.charAt(0).toUpperCase() : "";
  return (
    <Box sx={styles.container}>
      <Box sx={styles.logoContainer}>
        <Button sx={styles.menuContainer} onClick={onMenuClick}>
          <MenuIcon sx={styles.menu} />
        </Button>
        <Button onClick={onLogoClick}>
          <Image style={{ height: 40 }} img={logo} />
        </Button>
      </Box>
      <Button onClick={handleClick}>
        <Chip
          icon={<Avatar sx={styles.userAvatar}>{firstLetter}</Avatar>}
          sx={styles.chip}
          label={`${sellerName ?? ""}${
            sellerApobId ? ` (${sellerApobId})` : ""
          }`}
          variant="filled"
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={styles.logoutContainer}>
          <Logout />
        </Box>
      </Popover>
    </Box>
  );
}

export default Header;
