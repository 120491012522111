import React from "react";
import Typography from "@mui/material/Typography";
import ScreenLayout from "../../components/ScreenLayout";
import theme from "../../theme";

const styles = {
  container: {},
};

const LandingPage = () => {
  return (
    <ScreenLayout containerStyle={styles.container}>
      <Typography color={theme.palette.secondary.main} variant={"h4"}>
        Please select a sub-menu option to continue
      </Typography>
    </ScreenLayout>
  );
};

export default LandingPage;
