import "./tablecomp.css";
import { Box, Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadingIcon from "@mui/icons-material/Downloading";
import FileUpload from "./FileUpload";
import { BulkPriceFetchRepo } from "../../repository/BulkPriceRepository";
import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { useAlert } from "../../context/AlertContext/hooks";
import { headersForTrackUploadTable } from "./BulkPriceConstants";

// interface ISellerProductMapping {
//   discountPercent: number;
//   fkhProductId: number;
//   productName: string;
//   sellerSKUId: string;
// }

const TableComponent = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  // const [sellerApobId, setSellerAbopId] = useState<string>("");
  // const [sellerProductMapping, setSellerProductMapping] = useState<
  //   ISellerProductMapping[]
  // >([]);
  const [sessionUssid, setSessionUssid] = useState<string>("");
  const [getStatus, setGetStatus] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [getLatestIngestionStatus, setGetLatestIngestionStatus] =
    useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  // const [page, setPage] = useState(0);
  // const [totalPages, setTotalPages] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const { setAlert } = useAlert();
  const validationInterval = useRef<number | null>(null);

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //   fetchPricing(page + 1, rowsPerPage);
  // }, [page, rowsPerPage]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getLatestRequest({ namespace: "pricing", action: "ingestion" });
  }, []);

  useEffect(() => {
    if (
      getStatus === "SUCCESS" ||
      getStatus === "FAILED" ||
      getStatus === "TIMER_EXPIRED"
    ) {
      if (validationInterval.current !== null) {
        clearInterval(validationInterval?.current);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    () => {
      if (validationInterval.current !== null) {
        clearInterval(validationInterval?.current);
      }
    };
  }, [getStatus]);

  const [validationData, setValidationData] = useState([
    {
      sessionUssid: sessionUssid,
      createdAt: createdAt,
      getStatus: getStatus,
    },
  ]);

  const isBulkPriceButtonDisabled =
    (getStatus !== "SUCCESS" &&
      getStatus !== "FAILED" &&
      getStatus !== "TIMER_EXPIRED" &&
      getStatus !== "") ||
    (getLatestIngestionStatus !== "SUCCESS" &&
      getLatestIngestionStatus !== "FAILED" &&
      getLatestIngestionStatus !== "TIMER_EXPIRED" &&
      getLatestIngestionStatus !== "");
  //API Calls

  // const fetchPricing = async (pageNumber: number, maxResults: number) => {
  //   try {
  //     let res: any = await BulkPriceFetchRepo.ViewPricing({
  //       sellerApobId,
  //       pageNumber,
  //       maxResults,
  //     });

  //     res = await res.json();
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  //     setSellerAbopId(res?.sellerApobId ?? "");
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  //     // setSellerProductMapping(res?.SellerProductMapping ?? []);
  //     // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  //     // setTotalPages(res?.totalPages ?? 0);
  //   } catch {}
  // };

  const sessionStart = async () => {
    try {
      let res: any = await BulkPriceFetchRepo.createSession({
        namespace: "pricing",
        uploadBy: "seller-panel",
        fileType: ".csv, .xlsx",
      });
      if (res.status !== 200) {
        return null;
      }
      res = await res.json();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSessionUssid(res?.ussid ?? "");

      if (res?.ussid) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        localStorage.setItem("session-ussid", res?.ussid);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return res.ussid;
    } catch {}
  };

  const statusCut = async () => {
    try {
      await BulkPriceFetchRepo.statusCut({
        sessionUssid,
      });
    } catch {}
  };

  const uploadSession = async (responseUssid: any) => {
    try {
      const res: any = await BulkPriceFetchRepo.uploadSession({
        selectedFile,
        sessionUssid: responseUssid,
      });
      if (res.status !== 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.status;
      }
    } catch (e) {}
  };

  const sessionClose = async () => {
    try {
      await BulkPriceFetchRepo.closeSession({
        sessionUssid,
      });
    } catch {}
  };

  const polling = async ({ action, ussid, callfrom }: any) => {
    try {
      const resp: any = await BulkPriceFetchRepo.getStatus({
        ussid,
        action,
      });
      if (resp.status !== 200) throw new Error("Error");
      const res = await resp.json();
      if (callfrom === "getStatus") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGetStatus(res?.status ?? "");
        setValidationData([
          {
            createdAt: res?.createdAt,
            getStatus: res?.status,
            sessionUssid: res?.ussid,
          },
        ]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCreatedAt(res?.createdAt ?? "");
      } else if (callfrom === "getLatestIngestion") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGetLatestIngestionStatus(res?.status ?? "");
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setValidationData([
          {
            createdAt: res?.createdAt,
            getStatus: res?.status,
            sessionUssid: res?.ussid,
          },
        ]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.status;
      }
    } catch {}
  };

  const validation = async () => {
    try {
      let res: any = await BulkPriceFetchRepo.getReport({
        sessionUssid,
      });
      res = await res.json();
      const valUrl: string | null = res.url;
      return valUrl;
    } catch {}
  };

  const pollingAndUpdateGetLatestIngestionInterval = (
    latestUssid: any,
    interval: NodeJS.Timeout | null
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      polling({
        action: "ingestion",
        ussid: latestUssid,
        callfrom: "getLatestIngestion",
      })
        .then((res: string) => {
          if (
            res === "SUCCESS" ||
            res === "FAILED" ||
            res === "TIMER_EXPIRED"
          ) {
            clearInterval(interval as NodeJS.Timeout);
            resolve(res);
          }
        })
        .catch((error: any) => {
          clearInterval(interval as NodeJS.Timeout);
          reject(error);
        });
    });
  };

  const getLatestIngestion = (latestUssid: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (latestUssid !== null) {
        // Call first poll immediately
        pollingAndUpdateGetLatestIngestionInterval(latestUssid, null)
          .then((res: string) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });

        // Call other polls with an interval of 7 seconds
        const interval = setInterval(() => {
          pollingAndUpdateGetLatestIngestionInterval(latestUssid, interval)
            .then((res: string) => {
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        }, 5000);
      }
    });
  };

  const getLatestRequest = async ({ namespace, action }: any) => {
    let res: any = null;
    try {
      res = await BulkPriceFetchRepo.getLatestRequest({
        namespace,
        action,
      });
      if (res.status !== 200) return;
      res = await res.json();
    } catch {}

    getLatestIngestion(res.ussid);
  };

  const downloadSnapshot = async () => {
    try {
      let res: any = await BulkPriceFetchRepo.downloadSnapshot({});
      res = await res.json();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      //   setEpochTime(res?.createdAt ?? "");
      const fileReqUrl: string | null = res.url;
      return fileReqUrl;
    } catch (error) {
      setAlert(
        `An error occurred: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
  };

  // handle Functions

  const handlePolling = (respStatus: any) => {
    polling({
      action: "ingestion",
      ussid: respStatus,
      callfrom: "getStatus",
    });
    validationInterval.current = window.setInterval(() => {
      polling({
        action: "ingestion",
        ussid: respStatus,
        callfrom: "getStatus",
      });
    }, 7000);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const res: any = await sessionStart();
      setModalOpen(false);
      const uploadStatus: any = await uploadSession(res);
      if (res == null || uploadStatus) {
        alert("File Upload failed, Please try again.");
        await statusCut();
      } else {
        setSuccessfulUpdate(true);
        await sessionClose();
        handlePolling(res);
      }
    }
  };

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleUpdatePricing = () => {
    handleUpload();
    setTimeout(() => {
      setSelectedFile(null);
    }, 2000);
  };

  const handleDialogClose = () => {
    setSuccessfulUpdate(false);
  };

  const handleDownload = async () => {
    const validUrl: any = await validation();
    try {
      const link = document.createElement("a");
      link.href = validUrl;
      link.setAttribute("download", `RequestedFile.csv`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } catch (error) {}
  };

  const handleDownloadfilereq = async () => {
    const reqUrl: any = await downloadSnapshot();
    try {
      const link = document.createElement("a");
      link.href = reqUrl;
      link.setAttribute("download", `RequestedFile.csv`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } catch (error) {}
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedFile(null);
    //sessionClose();
    statusCut();
  };

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
      <div className="container">
        <div
          className="buttonGroup"
          style={{ borderTop: "solid 20px #EEF2F6" }}
        >
          <Box>
            {isBulkPriceButtonDisabled ? (
              <Tooltip
                title="Upload in progress. Please try again after sometime"
                placement="top"
              >
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    className="button"
                    sx={{ margin: "0 20px" }}
                    disabled={isBulkPriceButtonDisabled}
                  >
                    Bulk Price Update
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className="button"
                sx={{ margin: "0 20px" }}
                disabled={isBulkPriceButtonDisabled}
              >
                Bulk Price Update
              </Button>
            )}
            <Dialog open={modalOpen} onClose={handleClose}>
              <DialogTitle
                id="dialog-title"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "24px 0 24px 24px",
                }}
              >
                {"Upload Updated Pricing"}
                <Button
                  startIcon={<CloseRoundedIcon />}
                  onClick={handleClose}
                  sx={{ color: "black", padding: "0" }}
                ></Button>
              </DialogTitle>
              <DialogContent sx={{ padding: "10px 25px" }}>
                <FileUpload
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  handleClose={handleClose}
                  handleUpdatePricing={handleUpdatePricing}
                  handleDownloadfilereq={handleDownloadfilereq}
                />
                <DialogContentText
                  id="description"
                  sx={{
                    padding: "15px",
                    fontSize: "14px",
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                  }}
                >
                  To update pricing, make changes to the current pricing file
                  and upload the updated version here.
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
                <Button
                  onClick={handleDownloadfilereq}
                  sx={{ marginBottom: "16px" }}
                >
                  Download Current Pricing File
                </Button>
                <Button
                  onClick={handleUpdatePricing}
                  sx={{
                    marginBottom: "16px",
                    backgroundcolor: "primary",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Update Pricing
                </Button>
              </DialogActions>
            </Dialog>
            <Button
              variant="contained"
              className="button"
              sx={{
                backgroundColor: "green",
                color: "white",
                margin: "0 20px",
              }}
              onClick={handleDownloadfilereq}
              startIcon={<DownloadingIcon />}
            >
              Download Pricing File
            </Button>
          </Box>
        </div>

        <Dialog open={successfulUpdate} onClose={handleDialogClose}>
          <DialogTitle
            id="dialog-title"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "24px 0 24px 24px",
            }}
          >
            {"Upload Successful"}
            <Button
              startIcon={<CloseRoundedIcon />}
              onClick={handleDialogClose}
              sx={{ color: "black", padding: "0" }}
            ></Button>
          </DialogTitle>
          <DialogContent sx={{ padding: "10px 25px" }}>
            <DialogContentText
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon />
            </DialogContentText>
            <DialogContentText
              id="description"
              sx={{
                padding: " 35px 65px 25px 65px",
                fontSize: "14px",
                display: "flex",
                alignContent: "center",
                margin: "auto",
              }}
            >
              Your Pricing file upload request has been placed. You can track
              the request ID {sessionUssid} on the Price update screen.{" "}
            </DialogContentText>
            <DialogContentText
              id="description"
              sx={{
                padding: " 15px 65px 25px 65px",
                fontSize: "14px",
                fontWeight: 600,
                display: "flex",
                alignContent: "center",
                margin: "auto",
              }}
            >
              The Pricing File will be updated with the new Discount Percent
              Values in some Time.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              onClick={handleDialogClose}
              sx={{
                marginBottom: "16px",
                backgroundcolor: "primary",
                color: "white",
              }}
              variant="contained"
            >
              Ok! Great
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            fontFamily: "Roboto",
            fontWeight: 600,
            fontSize: "14px",
            color: "#323A4F",
            paddingLeft: "10px",
            "& p": {
              marginTop: "5px",
            },
          }}
        >
          <p>Track Upload Request</p>
        </Box>
        <TableContainer sx={{ marginBottom: "80px" }}>
          <Table sx={{ minWidth: "650px" }} aria-label="trackuploadtable">
            <TableHead sx={{ backgroundColor: "#EEF2F6" }}>
              <TableRow
                sx={{ width: "50px", fontSize: "14px", padding: "16px" }}
              >
                {headersForTrackUploadTable.map(
                  (
                    item: { name: string; sx?: { padding: string } },
                    index: number
                  ) => (
                    <TableCell key={index} sx={item.sx} className="tablecell">
                      {item.name}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {validationData.map((item, index) => (
                <TableRow
                  sx={{ width: "70px", fontSize: "14px", padding: "16px" }}
                  key={index}
                >
                  <TableCell className="tablecell">
                    {item.sessionUssid}
                  </TableCell>
                  <TableCell className="tablecell">{item.createdAt}</TableCell>
                  <TableCell className="tablecell">{item.getStatus}</TableCell>
                  <TableCell className="tablecell">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDownload}
                      disabled={item.getStatus !== "FAILED"}
                    >
                      Validation File
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TableContainer sx={{ marginBottom: 6, maxHeight: "650px" }}>
          <Table sx={{ minWidth: "650px" }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#EEF2F6" }}>
              <TableRow
                sx={{ width: "50px", fontSize: "14px", padding: "16px" }}
              >
                {headers.map((item: { name: string }, index: number) => (
                  <TableCell
                    key={index}
                    sx={{
                      padding: "10px 70px 10px 24px",
                    }}
                    className="tablecell"
                  >
                    {item.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            // TODO : Test Table render, after view API is fixed 
            <TableBody>
              {sellerProductMapping?.map(
                (item: ISellerProductMapping, index: number) => (
                  <TableRow
                    sx={{
                      width: "70px",
                      fontSize: "14px",
                      padding: "16px",
                    }}
                    key={index}
                  >
                    {tableCellData.map(
                      (cell: { key: string }, cellIndex: number) => {
                        const cellValue =
                          item[cell.key as keyof ISellerProductMapping];
                        const cellContent =
                          cellValue != null ? cellValue.toString() : "";
                        return (
                          <Tooltip title={cellContent} key={cellIndex}>
                            <TableCell
                              className="tableCell"
                              title={cellContent}
                            >
                              {cell.key === "productName"
                                ? cellContent.substring(0, 35)
                                : cellContent.substring(0, 25)}
                            </TableCell>
                          </Tooltip>
                        );
                      }
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer> 
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ borderBottom: "solid 20px #EEF2F6" }}
        /> */}
      </div>
    </>
  );
};

export default TableComponent;
