import React from "react";
import "./App.css";
import theme from "./theme";
import NavigationRoutes from "./navigation/NavigationRoutes";
import AuthProvider from "./context/AuthContext/AuthProvider";
import AnimProvider from "./context/AnimationContext/AnimProvider";
import { ThemeProvider } from "@mui/material";
import AlertProvider from "./context/AlertContext/AlertProvider";
//
function App() {
  return (
    <AlertProvider>
      <AuthProvider>
        <AnimProvider>
          <ThemeProvider theme={theme}>
            <NavigationRoutes />
          </ThemeProvider>
        </AnimProvider>
      </AuthProvider>
    </AlertProvider>
  );
}

export default App;
