export const headers = [
  { name: "Seller SKU ID" },
  { name: "Product ID" },
  { name: "Product name" },
  { name: "Discount Percentage" },
];
export const headersForTrackUploadTable = [
  { name: "Status ID", sx: { padding: "10px 0px 10px 200px" } },
  { name: "Upload Date", sx: { padding: "10px 70px 10px 40px" } },
  { name: "Validation Status", sx: { padding: "10px 70px 10px 0px" } },
  { name: "Actions", sx: { padding: "10px 24px" } },
];
export const tableCellData = [
  //   { key: "productId" },
  { key: "sellerSKUId" },
  { key: "fkhProductId" },
  { key: "productName" },
  { key: "discountPercent" },
];
