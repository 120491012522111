import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";

const styles = {
  container: {
    backgroundColor: "white",
    // width: "45%",
    borderRadius: "8px",
    // padding: 5,
    // height: "50%",
    // boxShadow: "1px 2px 9px #888",
    margin: 2,
    height: "95%",
    // height: window.innerHeight * 0.9
  },
};

interface IScreenLayout {
  children: ReactNode;
  containerStyle?: SxProps;
}

const SubLayout = (props: IScreenLayout) => {
  const { children, containerStyle = {} } = props;
  return (
    <Box component={"div"} sx={{ ...styles.container, ...containerStyle }}>
      {children}
    </Box>
  );
};

export default SubLayout;
