import React, { CSSProperties } from "react";
import logo from "../../assets/logo.png";

interface IProps {
  alt?: string;
  img: any;
  style: CSSProperties | undefined;
}
const Image = (props: IProps) => {
  const { img = logo, style = {}, alt = "" } = props;
  return <img src={img} style={style} alt={alt} />;
};

export default Image;
