import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ScreenLayout from "../../components/ScreenLayout";
import SubLayout from "../../components/ScreenLayout/SubLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./style.css";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TablePagination,
  Toolbar,
  Tooltip,
} from "@mui/material";

import DatePickerCustom from "../../components/DatePickerCustom";
import { useState } from "react";
import { PrescriptionRepository } from "../../repository/PrescriptionRepository";
import CloseIcon from "@mui/icons-material/Close";
import { DateUtil, allNull, includesNull } from "../../utils/DateUtils";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../context/AlertContext/hooks";
import { useAuth } from "../../context/AuthContext/hooks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InfoOutlined } from "@mui/icons-material";
// import { SnackbarOrigin } from "@mui/material/Snackbar";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IShipmentItemInterface {
  batchNumber: number;
  displayName: string;
  expiryDate: number;
  itemQuantity: number;
  prescriptionOTC: string;
  price: number;
  productId: number;
  sellerLotId: string;
  sellerSKUId: string;
}

export interface IPendingOrders {
  custUserId: string;
  dispatchDate: number;
  logisticPartner: string;
  orderDate: number;
  packedState: boolean;
  sellerApobId: string;
  sellerId: number;
  sellerName: string;
  shipmentId: number;
  shipmentItems?: IShipmentItemInterface[];
}

interface IPrescriptionList {
  id: string;
  preSignedUrl: string;
}

interface IFetchPrescriptionOrder {
  orderId: string;
  prescriptions: IPrescriptionList[];
  sellerApobId: string;
}

const styles = {
  subContainer: {
    display: "flex",
    flexDirection: "column",
  },
};
const PrescriptingPending = ({
  showActions = true,
}: {
  packedState?: Record<string, boolean>;
  showActions?: boolean;
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [historyPageNo, setHistoryPageNo] = React.useState(0);
  const [rowsPerHistoryPage, setRowsPerHistoryPage] = React.useState(10);
  const [data, setData] = React.useState<IPendingOrders[]>(() => []);
  const [historyData, setHistoryData] = React.useState<IPendingOrders[]>(
    () => []
  );
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const auth = useAuth();
  const [orderDateRange, setOrderDateRange] = React.useState([null, null]);
  const [orderStartDate, orderEndDate] = orderDateRange;
  const [dispatchDateRange, setDispatchDateRange] = React.useState<any[]>([
    null,
    null,
  ]);
  const [prescription, setPrescription] = useState<IPrescriptionList[]>([]);
  const [dispatchStartDate, dispatchEndDate] = dispatchDateRange;
  const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
  const [showViewHistoryModal, setShowViewHistoryModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IPendingOrders | null>(null);
  const [selectedHistoryItem, setSelectedHistoryItem] =
    useState<IPendingOrders | null>(null);

  const [confirmRejectModal, setConfirmRejectModal] = useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const fetchPendingOrder = (options?: any) => {
    setFetchLoading(true);
    PrescriptionRepository.fetchPendingOrder({
      crmState: "VERIFIED",
      states: ["PLACED"],
      ...(!!dispatchStartDate && !!dispatchEndDate
        ? {
            scheduleDeliveryDate: {
              from: DateUtil.formatDate(String(dispatchStartDate), true),
              to: DateUtil.formatDate(String(dispatchEndDate), true),
            },
          }
        : {}),
      ...(!!orderStartDate && !!orderEndDate
        ? {
            orderDate: {
              from: DateUtil.formatDate(String(orderStartDate), true),
              to: DateUtil.formatDate(String(orderEndDate), true),
            },
          }
        : {}),
      ...options,
    })
      .then(async (res) => {
        if (res?.status == 200 || res?.status == 500) {
          const content = await res.json();
          const localData: IPendingOrders[] = content.shipments.map(
            (item: IPendingOrders): IPendingOrders => {
              return {
                ...item,
                packedState: false,
                //@ts-ignore
                dispatchDate: DateUtil.formatDate(item.dispatchDate),
                //@ts-ignore
                orderDate: DateUtil.formatDate(item.orderDate),
              };
            }
          );
          setData(localData);
        } else {
          if (res.status > 200 && res.status < 299) {
            const errorRes = await res.json();
            setData([]);
            setAlert(
              `${String(errorRes?.code)} ${String(errorRes?.message)}`,
              "info"
            );
          } else if (res.status == 401) {
            auth.signout(() => {
              navigate("/login");
            });
            return;
          } else {
            const errorRes = await res.json();
            setAlert(
              `${String(errorRes?.code)} ${String(errorRes?.message)}`,
              "error"
            );
          }
        }
      })
      .catch((e) => {
        console.log({ e });
      })
      .finally(() => {
        setFetchLoading(false);
      });
  };

  const fetchHistoryOrder = (userId: string, options?: any) => {
    setFetchLoading(true);
    PrescriptionRepository.fetchHistoryOrder(userId, {
      crmState: "VERIFIED",
      states: ["DELIVERED"],
      ...(!!dispatchStartDate && !!dispatchEndDate
        ? {
            scheduleDeliveryDate: {
              from: DateUtil.formatDate(String(dispatchStartDate), true),
              to: DateUtil.formatDate(String(dispatchEndDate), true),
            },
          }
        : {}),
      ...(!!orderStartDate && !!orderEndDate
        ? {
            orderDate: {
              from: DateUtil.formatDate(String(orderStartDate), true),
              to: DateUtil.formatDate(String(orderEndDate), true),
            },
          }
        : {}),
      ...options,
    })
      .then(async (res) => {
        if (res?.status == 200 || res?.status == 500) {
          const content = await res.json();
          const localData: IPendingOrders[] = content.shipments.map(
            (item: IPendingOrders): IPendingOrders => {
              return {
                ...item,
                packedState: false,
                //@ts-ignore
                dispatchDate: DateUtil.formatDate(item.dispatchDate),
                //@ts-ignore
                orderDate: DateUtil.formatDate(item.orderDate),
              };
            }
          );
          setHistoryData(localData);
        } else {
          if (res.status > 200 && res.status < 299) {
            const errorRes = await res.json();
            setHistoryData([]);
            setAlert(
              `${String(errorRes?.code)} ${String(errorRes?.message)}`,
              "info"
            );
          } else if (res.status == 401) {
            auth.signout(() => {
              navigate("/login");
            });
            return;
          } else {
            const errorRes = await res.json();
            setAlert(
              `${String(errorRes?.code)} ${String(errorRes?.message)}`,
              "error"
            );
          }
        }
      })
      .catch((e) => {
        console.log({ e });
      })
      .finally(() => {
        setFetchLoading(false);
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeRowsPerHistoryPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerHistoryPage(parseInt(event.target.value, 10));
    setHistoryPageNo(0);
  };
  const handleChangeHistoryPage = (event: unknown, newPage: number) => {
    setHistoryPageNo(newPage);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const emptyRowsHistory =
    historyPageNo > 0
      ? Math.max(
          0,
          (1 + historyPageNo) * rowsPerHistoryPage - historyData.length
        )
      : 0;

  const visibleRows = React.useMemo(
    () => data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, data]
  );

  const visibleHistoryRows = React.useMemo(
    () =>
      historyData.slice(
        historyPageNo * rowsPerHistoryPage,
        historyPageNo * rowsPerHistoryPage + rowsPerHistoryPage
      ),
    [historyPageNo, rowsPerHistoryPage, historyData]
  );

  React.useEffect(() => {
    if (!includesNull(orderDateRange) || allNull(orderDateRange)) {
      fetchPendingOrder();
    }
  }, [orderDateRange]);

  React.useEffect(() => {
    if (!includesNull(dispatchDateRange) || allNull(dispatchDateRange)) {
      fetchPendingOrder();
    }
  }, [dispatchDateRange]);

  React.useEffect(() => {
    fetchPendingOrder();
  }, []);

  //@ts-ignore
  const viewPrescriptionModal = async (item: IPendingOrders) => {
    const res = await PrescriptionRepository.fetchPrescription(
      item?.shipmentId.toString()
    );
    const res2: IFetchPrescriptionOrder = await res.json();
    setPrescription(res2?.prescriptions);
    setSelectedItem(item);
    setShowPrescriptionModal(true);
  };

  const approvePrescription = (orderId: number) => {
    PrescriptionRepository.approvePrescription(orderId).then((res) => {
      fetchPendingOrder();
    });
    setPrescription([]);
    setShowPrescriptionModal(false);
    setSelectedItem(null);
  };

  const viewHistoryModal = (item: IPendingOrders) => {
    const order = data.find((it) => it.shipmentId === item.shipmentId);
    if (order) {
      const custUserId = order.custUserId;
      fetchHistoryOrder(custUserId);
    }
    setSelectedHistoryItem(item);
    setShowViewHistoryModal(true);
  };
  const rejectPrescription = (orderId: number) => {
    PrescriptionRepository.cancelPrescription(orderId).then((res) => {
      fetchPendingOrder();
    });
    setPrescription([]);
    setShowPrescriptionModal(false);
    setSelectedItem(null);
    setConfirmRejectModal(false);
  };

  return (
    <ScreenLayout>
      <SubLayout containerStyle={styles.subContainer}>
        {fetchLoading ? (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={100} sx={{}} />
          </Box>
        ) : (
          <div className="fullContainer">
            <Toolbar
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <DatePickerCustom
                  endDate={dispatchEndDate}
                  startDate={dispatchStartDate}
                  setDateRange={(date: any[]) => {
                    setDispatchDateRange(date);
                  }}
                  key={1}
                  title={"Dispatch Date Filter:"}
                />
                <DatePickerCustom
                  endDate={orderEndDate}
                  startDate={orderStartDate}
                  setDateRange={(date: any[]) => {
                    setOrderDateRange(date);
                  }}
                  key={2}
                  title={"Order Date Filter:"}
                />
              </Box>
            </Toolbar>

            <TableContainer component={Paper}>
              <Table className="table" aria-label="simple table">
                <TableHead className="tablehead">
                  <TableRow className="tablerowhead">
                    <TableCell className="tableCell">
                      Logistic Partner
                    </TableCell>
                    <TableCell className="tableCell">Dispatch Date</TableCell>
                    <TableCell className="tableCell">Order ID</TableCell>
                    <TableCell className="tableCell">Order Date</TableCell>
                    <TableCell className="tableCell">
                      Verify Prescription
                    </TableCell>
                    <TableCell></TableCell>
                    {showActions ? (
                      <TableCell className="tableCell">Approve</TableCell>
                    ) : null}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows.length ? (
                    visibleRows?.map((item, index) => {
                      return (
                        <>
                          <TableRow
                            className="tablerowbody"
                            hover
                            tabIndex={-1}
                            key={index}
                            sx={{
                              cursor: "pointer",
                              "& > *": { borderBottom: "unset" },
                            }}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <TableCell className="tableCell">
                              {item.logisticPartner}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.dispatchDate}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.shipmentId}
                            </TableCell>
                            <TableCell className="tableCell">
                              {item.orderDate}
                            </TableCell>
                            <TableCell className="tableCell">
                              <>
                                <IconButton
                                  sx={{
                                    strokeWidth: "1.5px",
                                    stroke: "#6C7280",
                                    color: "white",
                                    textAlign: "center",
                                  }}
                                  onClick={() => viewPrescriptionModal(item)}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </>
                            </TableCell>
                            <TableCell className="tableCell">
                              <Button
                                onClick={() => viewHistoryModal(item)}
                                style={{ color: "blue", textTransform: "none" }}
                              >
                                View History
                              </Button>
                            </TableCell>
                            {showActions ? (
                              <TableCell className="tableCell">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    approvePrescription(item?.shipmentId)
                                  }
                                >
                                  Approve
                                </Button>
                              </TableCell>
                            ) : null}
                            <TableCell className="tableCell">
                              <Tooltip
                                title="By clicking on approve, we declare that we have received, reviewed and actioned on the prescription as per applicable laws and order is being confirmed basis the requisite assessment done"
                                arrow
                              >
                                <InfoOutlined
                                  sx={{ color: "#848884", size: 10 }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p> No Data Available </p>
                    </Box>
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Dialog
              maxWidth="xl"
              onClose={() => setShowPrescriptionModal(false)}
              open={showPrescriptionModal}
            >
              <DialogTitle>
                {`Prescription Verification (Order Id: ${
                  selectedItem?.shipmentId ?? ""
                })`}
                <IconButton
                  aria-label="close"
                  onClick={() => setShowPrescriptionModal(false)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 18,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ width: "50%" }}>
                    <Table size="small" aria-label="collapse-table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell> Batch Number</TableCell>
                          <TableCell>MRP</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>
                      {/* Your TableBody goes here */}
                      <TableBody>
                        {(selectedItem?.shipmentItems ?? []).map(
                          (id: IShipmentItemInterface) => (
                            // eslint-disable-next-line react/jsx-key
                            <TableRow
                              sx={{
                                "&:last-child td": {
                                  borderBottom: "none",
                                },
                              }}
                            >
                              <TableCell>
                                {id?.prescriptionOTC === "P" ? "Rx" : null}
                              </TableCell>
                              <TableCell>{id.displayName}</TableCell>
                              <TableCell className="tableCell">
                                {id.batchNumber}
                              </TableCell>
                              <TableCell className="tableCell">
                                {id.price}
                              </TableCell>
                              <TableCell className="tableCell">
                                {id.itemQuantity}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    {prescription.length
                      ? prescription.map((item) => (
                          <img
                            //@ts-ignore
                            key={item?.id}
                            //@ts-ignore
                            src={item?.preSignedUrl ?? ""}
                            alt="prescription"
                            style={{ objectFit: "contain", width: "100%" }}
                          />
                        ))
                      : null}
                  </Box>
                </Box>
                {!showViewHistoryModal ? (
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: 300,
                      fontWeight: 600,
                    }}
                  >
                    By clicking on approve, we declare that we have received,
                    reviewed and actioned on the prescription as per applicable
                    laws and order is being confirmed basis the requisite
                    assessment done
                  </p>
                ) : (
                  <></>
                )}
              </DialogContent>
              {showActions && !showViewHistoryModal ? (
                <DialogActions sx={{ padding: "15px" }}>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: "10px", color: "red" }}
                    onClick={() => setConfirmRejectModal(true)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      //@ts-ignore
                      approvePrescription(selectedItem?.shipmentId)
                    }
                  >
                    Approve
                  </Button>
                </DialogActions>
              ) : null}
            </Dialog>

            <Dialog
              maxWidth="xl"
              open={showViewHistoryModal}
              onClose={() => setShowViewHistoryModal(false)}
            >
              <DialogTitle>
                {`Previous One Year History (Order Id: ${
                  selectedHistoryItem?.shipmentId ?? ""
                })`}
                <IconButton
                  aria-label="close"
                  onClick={() => setShowViewHistoryModal(false)}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 18,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="collapse-table">
                    <TableHead className="tablehead">
                      <TableRow className="tablerowhead">
                        <TableCell className="tableCell">
                          Logistic Partner
                        </TableCell>
                        <TableCell className="tableCell">
                          Dispatch Date
                        </TableCell>
                        <TableCell className="tableCell">Order ID</TableCell>
                        <TableCell className="tableCell">Order Date</TableCell>
                        <TableCell className="tableCell">
                          Verify Prescription
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {visibleHistoryRows.length ? (
                        visibleHistoryRows?.map((item, index) => {
                          return (
                            <>
                              <TableRow
                                className="tablerowbody"
                                hover
                                tabIndex={-1}
                                key={index}
                                sx={{
                                  cursor: "pointer",
                                  "& > *": { borderBottom: "unset" },
                                }}
                                onClick={(event) => event.stopPropagation()}
                              >
                                <TableCell className="tableCell">
                                  {item.logisticPartner}
                                </TableCell>
                                <TableCell className="tableCell">
                                  {item.dispatchDate}
                                </TableCell>
                                <TableCell className="tableCell">
                                  {item.shipmentId}
                                </TableCell>
                                <TableCell className="tableCell">
                                  {item.orderDate}
                                </TableCell>
                                <TableCell className="tableCell">
                                  <>
                                    <IconButton
                                      sx={{
                                        strokeWidth: "1.5px",
                                        stroke: "#6C7280",
                                        color: "white",
                                        textAlign: "center",
                                      }}
                                      onClick={() =>
                                        viewPrescriptionModal(item)
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p> No Data Available </p>
                        </Box>
                      )}
                      {emptyRowsHistory > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRowsHistory,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={historyData.length}
                  rowsPerPage={rowsPerHistoryPage}
                  page={historyPageNo}
                  onPageChange={handleChangeHistoryPage}
                  onRowsPerPageChange={handleChangeRowsPerHistoryPage}
                />
              </DialogContent>
            </Dialog>
            {confirmRejectModal ? (
              <Dialog
                open={confirmRejectModal}
                onClose={() => {
                  setConfirmRejectModal(false);
                }}
              >
                <DialogContent>
                  {`Are you sure to reject this order. (id: ${
                    selectedItem?.shipmentId ?? ""
                  })`}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setConfirmRejectModal(false);
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    //@ts-ignore
                    onClick={() => rejectPrescription(selectedItem?.shipmentId)}
                    color="primary"
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </SubLayout>
    </ScreenLayout>
  );
};
export default PrescriptingPending;
