import React from "react";
import { Alert } from "@mui/material";
import { useAlert } from "../../context/AlertContext/hooks";
import Box from "@mui/material/Box";

const AlertPopup = () => {
  const { text, type } = useAlert();

  if (text && type) {
    return (
      <Box>
        <Alert
          severity={type}
          sx={{
            zIndex: 10,
          }}
        >
          {text}
        </Alert>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default AlertPopup;
