import * as React from "react";
import CommonScreenComponent, {
  States,
} from "../CommonScreenComponent/index_backup";

const PrintInvoice = () => {
  return <CommonScreenComponent states={States.PACKED} />;
};

export default PrintInvoice;
