import { ConfigHelper } from "../utils/ConfigHelper";

export interface ILoginBody {
  password: string | FormDataEntryValue | null;
  username: string | FormDataEntryValue | null;
}

class LoginRepository {
  public loginApi = async (res: ILoginBody) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(res),
      headers: myHeaders,
    };
    return fetch(ConfigHelper.getBaseUrl() + "/login", requestOptions);
  };
}

const loginRepository = new LoginRepository();
export { loginRepository as LoginRepository };
