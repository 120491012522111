import React from "react";
import AuthContext from "./index";
import { ILoginBody, LoginRepository } from "../../repository/LoginRepository";
import StorageUtils, { StorageKeys } from "../../utils/StorageUtils";

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<{
    sellerApobId: string;
    sellerName: string;
    sessionId: string;
  } | null>();

  const signin = (newUser: ILoginBody, callback: (val: boolean) => void) => {
    if (!newUser) return;
    LoginRepository.loginApi(newUser).then(async (res) => {
      if (res.status == 200) {
        const { sellerApobId, sessionId } = await res.json();
        const sellerName = String(newUser?.username);
        StorageUtils().setItem(StorageKeys.sessionId, String(sessionId));
        StorageUtils().setItem(StorageKeys.sellerApobId, String(sellerApobId));
        StorageUtils().setItem(
          StorageKeys.sellerName,
          String(newUser?.username)
        );
        setUser({ sellerApobId, sessionId, sellerName });
        callback(true);
      } else {
        callback(false);
      }
    });
  };

  const signout = (callback: VoidFunction) => {
    StorageUtils().removeItem(StorageKeys.isLoggedIn);
    setUser(null);
    callback();
  };

  const value = { user, signin, signout, setUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
