import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import Image from "../../components/Image/image";
import logo from "../../assets/logo-webp.webp";
import { Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import StorageUtils from "../../utils/StorageUtils";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext/hooks";

const styles = {
  container: {
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.default,
    height: window.innerHeight,
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: 5,
    borderRadius: "8px",
    boxShadow: "1px 2px 9px #888",
    width: "20%",
    justifyContent: "center",
  },
  imageLogo: {
    height: 80,
  },
  title: { mt: 1, mb: 1, color: theme.palette.secondary.main },
  form: { mt: 1 },
  buttonStyle: {
    mt: 3,
    mb: 2,
    color: "white",
    backgroundColor: theme.palette.secondary.dark,
  },
  dividerStyle: { mt: 1, mb: 1 },
  forgotPassword: {
    color: theme.palette.secondary.light,
  },
  errorText: {
    color: theme.palette.error.main,
    textAlign: "center",
  },
  extraText: {
    color: theme.palette.secondary.light,
  },
};
export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [error, SetError] = useState<null | { msg: string }>(null);

  const from: string = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (auth.user) {
      navigate(from);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    auth.signin(
      { username: data.get("username"), password: data.get("password") },
      (val) => {
        if (val) {
          StorageUtils().setISLoggedIn("true");
          SetError(null);
          navigate(from, { replace: true });
        } else {
          StorageUtils().setISLoggedIn("false");
          SetError({ msg: "Invalid Credentials" });
        }
      }
    );
  };

  return (
    <Box
      sx={styles.container}
      component={"div"}
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box sx={styles.subContainer}>
        <Image style={styles.imageLogo} img={logo} />
        <Typography variant={"body1"} sx={styles.title}>
          Login to your Seller Account
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={styles.form}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            size={"medium"}
            label="Username"
            name="username"
            autoComplete="username"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size={"medium"}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={styles.buttonStyle}
          >
            Log in
          </Button>

          {error?.msg && (
            <Typography sx={styles.errorText} variant={"body1"}>
              {error?.msg}
            </Typography>
          )}

          <Divider sx={styles.dividerStyle} />
          <Typography sx={styles.forgotPassword} variant={"body2"}>
            Forgot your password?
          </Typography>
          <Typography sx={styles.extraText} variant={"overline"}>
            **For any support or to raise an issue, kindly reach out to Seller
            Support team**
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
