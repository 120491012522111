import React from "react";
import { ILoginBody } from "../../repository/LoginRepository";

export interface ILoginResponse {
  sellerApobId: string;
  sellerName: string;
  sessionId: string;
}

interface IAuthContextType {
  setUser: (val: ILoginResponse) => void;
  signin: (user: ILoginBody, callback: (val: boolean) => void) => void;
  signout: (callback: VoidFunction) => void;
  user: any;
}
const AuthContext = React.createContext<IAuthContextType>(null!);
export default AuthContext;
