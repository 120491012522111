import "./tablecomp.css";
import { Box, Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DownloadingIcon from "@mui/icons-material/Downloading";
import FileUpload from "./Fileupload";
import { InventoryFetchRepo } from "../../repository/InventoryRepository";
import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { useAlert } from "../../context/AlertContext/hooks";
import {
  headers,
  headersForTrackUploadTable,
  tableCellData,
} from "./InventoryConstant";

interface ILot {
  availableQty: number;
  batchId: string;
  expiryDate: string;
  fkhProductId: string;
  mfgDate: string;
  mrp: number;
  productId: string;
  productName: string;
  quantity: number;
  sellerConsumedQty: number;
  sellerLotId: string;
  soldQty: number;
}

const TableComponent = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState<boolean>(false);
  const [sellerApobId, setSellerAbopId] = useState<string>("");
  const [lots, setLots] = useState<ILot[]>([]);
  const [sessionUssid, setSessionUssid] = useState<string>("");
  const [getStatus, setGetStatus] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [createdAt, setCreatedAt] = useState<string>("");
  const [fileReqStatus, setFileReqStatus] = useState<string>("");
  const [getLatestSnapshotStatus, setGetLatestSnapshotStatus] =
    useState<string>("");
  const [getLatestIngestionStatus, setGetLatestIngestionStatus] =
    useState<string>("");
  const [epochTime, setEpochTime] = useState<number | undefined>();
  const [enableEpoch, setEnableEpoch] = useState<boolean>(false);
  const [isBulkInventoryButtonDisabled, setIsBulkInventoryDisabled] =
    useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [callToUpdateSnapshot, setCallToUpdateSnapshot] =
    useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const { setAlert } = useAlert();
  const validationInterval = useRef<number | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    fetchInventory(page + 1, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getLatestRequest({
      namespace: "inventory",
      action: "snapshot",
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getLatestRequest({ namespace: "inventory", action: "ingestion" });
  }, []);

  useEffect(() => {
    if (
      getStatus === "SUCCESS" ||
      getStatus === "FAILED" ||
      getStatus === "TIMER_EXPIRED"
    ) {
      if (validationInterval.current !== null) {
        clearInterval(validationInterval?.current);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    () => {
      if (validationInterval.current !== null) {
        clearInterval(validationInterval?.current);
      }
    };
  }, [getStatus]);

  useEffect(() => {
    const tempResult =
      (getStatus !== "SUCCESS" &&
        getStatus !== "FAILED" &&
        getStatus !== "TIMER_EXPIRED" &&
        getStatus !== "") ||
      (getLatestIngestionStatus !== "SUCCESS" &&
        getLatestIngestionStatus !== "FAILED" &&
        getLatestIngestionStatus !== "TIMER_EXPIRED" &&
        getLatestIngestionStatus !== "");
    setIsBulkInventoryDisabled(tempResult);
  }, [getStatus, getLatestIngestionStatus]);

  const [validationData, setValidationData] = useState([
    {
      sessionUssid: sessionUssid,
      createdAt: createdAt,
      getStatus: getStatus,
    },
  ]);

  //API Calls

  const fetchInventory = async (pageNumber: number, maxResults: number) => {
    try {
      let res: any = await InventoryFetchRepo.inventoryView({
        sellerApobId,
        pageNumber,
        maxResults,
      });

      res = await res.json();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSellerAbopId(res?.sellerApobId ?? "");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setLots(res?.lots ?? []);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setTotalPages(res?.totalPages ?? 0);
    } catch {}
  };

  const sessionStart = async () => {
    try {
      let res: any = await InventoryFetchRepo.createSession({
        namespace: "inventory",
        uploadBy: "seller-panel",
        fileType: ".csv, .xlsx",
      });
      if (res.status !== 200) {
        return null;
      }
      res = await res.json();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSessionUssid(res?.ussid ?? "");

      if (res?.ussid) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        localStorage.setItem("session-ussid", res?.ussid);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return res.ussid;
    } catch {}
  };

  const statusCut = async () => {
    try {
      await InventoryFetchRepo.statusCut({
        sessionUssid,
      });
    } catch {}
  };

  const uploadChunks = async (responseUssid: any) => {
    try {
      const res: any = await InventoryFetchRepo.uploadChunks({
        selectedFile,
        sessionUssid: responseUssid,
      });
      if (res.status !== 200) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.status;
      }
    } catch (e) {}
  };

  const sessionClose = async () => {
    try {
      await InventoryFetchRepo.closeSession({
        sessionUssid,
      });
    } catch {}
  };

  const polling = async ({ action, ussid, callfrom }: any) => {
    try {
      const resp: any = await InventoryFetchRepo.getStatus({
        ussid,
        action,
      });
      if (resp.status !== 200) throw new Error("Error");
      const res = await resp.json();
      if (callfrom === "getStatus") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGetStatus(res?.status ?? "");
        setValidationData([
          {
            createdAt: res?.createdAt,
            getStatus: res?.status,
            sessionUssid: res?.ussid,
          },
        ]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCreatedAt(res?.createdAt ?? "");
      } else if (callfrom === "getLatestIngestion") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGetLatestIngestionStatus(res?.status ?? "");
        setValidationData([
          {
            createdAt: res?.createdAt,
            getStatus: res?.status,
            sessionUssid: res?.ussid,
          },
        ]);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.status;
      } else if (callfrom === "getLatestSnapshot") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setGetLatestSnapshotStatus(res?.status ?? "");
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.status;
      } else if (callfrom === "updateInventory") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setFileReqStatus(res?.status ?? "");
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.status;
      }
    } catch {}
  };

  const validation = async () => {
    try {
      let res: any = await InventoryFetchRepo.getReport({
        sessionUssid,
      });
      res = await res.json();
      const valUrl: string | null = res.url;
      return valUrl;
    } catch {}
  };

  const pollingAndUpdateGetLatestIngestionInterval = (
    latestUssid: any,
    interval: NodeJS.Timeout | null
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      polling({
        action: "ingestion",
        ussid: latestUssid,
        callfrom: "getLatestIngestion",
      })
        .then((res: string) => {
          if (
            res === "SUCCESS" ||
            res === "FAILED" ||
            res === "TIMER_EXPIRED"
          ) {
            clearInterval(interval as NodeJS.Timeout);
            resolve(res);
          }
        })
        .catch((error: any) => {
          clearInterval(interval as NodeJS.Timeout);
          reject(error);
        });
    });
  };

  const getLatestIngestion = (latestUssid: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (latestUssid !== null) {
        // Call first poll immediately
        pollingAndUpdateGetLatestIngestionInterval(latestUssid, null)
          .then((res: string) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });

        // Call other polls with an interval of 4 seconds
        const interval = setInterval(() => {
          pollingAndUpdateGetLatestIngestionInterval(latestUssid, interval)
            .then((res: string) => {
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        }, 4000);
      }
    });
  };

  const pollingAndUpdateGetLatestSnapshotInterval = (
    latestUssid: any,
    interval: NodeJS.Timeout | null
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      polling({
        action: "snapshot",
        ussid: latestUssid,
        callfrom: "getLatestSnapshot",
      })
        .then((res: string) => {
          if (
            res === "SUCCESS" ||
            res === "FAILED" ||
            res === "TIMER_EXPIRED"
          ) {
            clearInterval(interval as NodeJS.Timeout);
            resolve(res);
          }
        })
        .catch((error: any) => {
          clearInterval(interval as NodeJS.Timeout);
          reject(error);
        });
    });
  };

  const getLatestSnap = (latestUssid: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (latestUssid !== null) {
        // Call first poll immediately
        pollingAndUpdateGetLatestSnapshotInterval(latestUssid, null)
          .then((res: string) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });

        // Call other polls with an interval of 4 seconds
        const interval = setInterval(() => {
          pollingAndUpdateGetLatestSnapshotInterval(latestUssid, interval)
            .then((res: string) => {
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        }, 4000);
      }
    });
  };

  const getLatestRequest = async ({ namespace, action }: any) => {
    let res: any = null;
    try {
      res = await InventoryFetchRepo.getLatestRequest({
        namespace,
        action,
      });
      if (res.status !== 200) return;
      res = await res.json();
    } catch {}

    if (action === "ingestion") {
      getLatestIngestion(res.ussid);
    } else if (action === "snapshot") {
      if (res.ussid === null) {
        setGetLatestSnapshotStatus("FAILED");
      } else {
        getLatestSnap(res.ussid);
      }
    }
  };

  const pollingAndUpdateInventoryInterval = (
    fileid: any,
    interval: NodeJS.Timeout | null
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      polling({
        action: "snapshot",
        ussid: fileid,
        callfrom: "updateInventory",
      })
        .then((res: string) => {
          if (res === "SUCCESS" || res === "FAILED") {
            clearInterval(interval as NodeJS.Timeout);
            resolve(res);
          }
        })
        .catch((error: any) => {
          clearInterval(interval as NodeJS.Timeout);
          reject(error);
        });
    });
  };

  const statusForUpdateInventory = (fileid: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (fileid !== null) {
        // Call first poll immediately
        pollingAndUpdateInventoryInterval(fileid, null)
          .then((res: string) => {
            resolve(res);
          })
          .catch((error: any) => {
            reject(error);
          });

        // Call other polls with an interval of 7 seconds
        const interval = setInterval(() => {
          pollingAndUpdateInventoryInterval(fileid, interval)
            .then((res: string) => {
              resolve(res);
            })
            .catch((error: any) => {
              reject(error);
            });
        }, 7000);
      }
    });
  };

  const updateInventory = async () => {
    let res: any = null;
    try {
      res = await InventoryFetchRepo.updateInventory({});
      res = await res.json();
    } catch (error) {
      setAlert(
        `An error occurred: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
    setCallToUpdateSnapshot(true);
    if (res && res.ussid !== null) {
      statusForUpdateInventory(res.ussid).then((fileStatus) => {
        if (fileStatus === "SUCCESS") {
          setCallToUpdateSnapshot(false);
        }
      });
    }
  };

  const getInventorySnap = async () => {
    try {
      let res: any = await InventoryFetchRepo.getInventorySnap({});
      res = await res.json();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setEpochTime(res?.createdAt ?? "");
      const fileReqUrl: string | null = res.url;
      return fileReqUrl;
    } catch (error) {
      setAlert(
        `An error occurred: ${
          error instanceof Error ? error.message : "Unknown error"
        }`,
        "error"
      );
    }
  };

  //EpochTime Converter
  const convertEpochToDateTime = (
    epochTimeattr: number | undefined
  ): string => {
    if (epochTimeattr !== undefined) {
      const date = new Date(epochTimeattr);
      const day = date.getDate();
      const month = date.getMonth() + 1;

      const formattedDay = String(day).padStart(2, "0");
      const formattedMonth = String(month).padStart(2, "0");

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      return `${formattedDay}/${formattedMonth} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return "";
  };

  // handle Functions

  const handlePolling = (respStatus: any) => {
    polling({
      action: "ingestion",
      ussid: respStatus,
      callfrom: "getStatus",
    });
    validationInterval.current = window.setInterval(() => {
      polling({
        action: "ingestion",
        ussid: respStatus,
        callfrom: "getStatus",
      });
    }, 7000);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const res: any = await sessionStart();
      setModalOpen(false);
      const uploadStatus: any = await uploadChunks(res);
      if (res == null || uploadStatus) {
        alert("File Upload failed, Please try again.");
        await statusCut();
      } else {
        setSuccessfulUpdate(true);
        await sessionClose();
        handlePolling(res);
      }
    }
  };

  const handleRequestfile = () => {
    updateInventory();
  };

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleUpdateInventory = () => {
    handleUpload();
    setTimeout(() => {
      setSelectedFile(null);
    }, 2 * 1000);
  };

  const handleDialogClose = () => {
    setSuccessfulUpdate(false);
  };

  const handleDownload = async () => {
    const validUrl: any = await validation();
    try {
      const link = document.createElement("a");
      link.href = validUrl;
      link.setAttribute("download", `RequestedFile.csv`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } catch (error) {}
  };

  const handleDownloadfilereq = async () => {
    const reqUrl: any = await getInventorySnap();
    try {
      const link = document.createElement("a");
      link.href = reqUrl;
      link.setAttribute("download", `RequestedFile.csv`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 100);
    } catch (error) {}
    setEnableEpoch(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    //sessionClose();
    setSelectedFile(null);
    statusCut();
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <div className="container">
        <div
          className="buttonGroup"
          style={{ borderTop: "solid 20px #EEF2F6" }}
        >
          <Box>
            {isBulkInventoryButtonDisabled ? (
              <Tooltip
                title="Updload in progress. Please try again after sometime"
                placement="top"
              >
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    className="button"
                    sx={{ margin: "0 20px" }}
                    disabled={isBulkInventoryButtonDisabled}
                  >
                    Bulk Inventory Update
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className="button"
                sx={{ margin: "0 20px" }}
                disabled={isBulkInventoryButtonDisabled}
              >
                Bulk Inventory Update
              </Button>
            )}

            <Dialog open={modalOpen} onClose={handleClose}>
              <DialogTitle
                id="dialog-title"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "24px 0 24px 24px",
                }}
              >
                {"Upload Updated Inventory"}
                <Button
                  startIcon={<CloseRoundedIcon />}
                  onClick={handleClose}
                  sx={{ color: "black", padding: "0" }}
                ></Button>
              </DialogTitle>
              <DialogContent sx={{ padding: "10px 25px" }}>
                <FileUpload
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  handleClose={handleClose}
                  handleUpdateInventory={handleUpdateInventory}
                  handleDownloadfilereq={handleDownloadfilereq}
                  fileReqStatus={fileReqStatus}
                  getLatestSnapshotStatus={getLatestSnapshotStatus}
                  callToUpdateSnapshot={callToUpdateSnapshot}
                />
                <DialogContentText
                  id="description"
                  sx={{
                    padding: "15px",
                    fontSize: "14px",
                    display: "flex",
                    alignContent: "center",
                    margin: "auto",
                  }}
                >
                  To update inventory, make changes to the current inventory
                  file and upload the updated version here.
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
                {(fileReqStatus === "SUCCESS" ||
                  getLatestSnapshotStatus === "SUCCESS") &&
                  !callToUpdateSnapshot && (
                    <Button
                      sx={{
                        marginBottom: "16px",
                      }}
                      onClick={handleDownloadfilereq}
                    >
                      Download Current Inventory
                    </Button>
                  )}
                <Button
                  onClick={handleUpdateInventory}
                  sx={{
                    marginBottom: "16px",
                    backgroundcolor: "primary",
                    color: "white",
                  }}
                  variant="contained"
                >
                  Update Inventory
                </Button>
              </DialogActions>
            </Dialog>
            {
              <Button
                variant="contained"
                color="secondary"
                className="button"
                onClick={handleRequestfile}
                sx={{ margin: "0 20px" }}
              >
                Request Inventory File
              </Button>
            }
            {(fileReqStatus === "PROCESSING" ||
              getLatestSnapshotStatus === "PROCESSING" ||
              getLatestSnapshotStatus === "CREATED" ||
              fileReqStatus === "CREATED") && (
              <Button
                variant="contained"
                className="button"
                sx={{
                  backgroundColor: "#FADC43",
                  color: "white",
                  margin: "0 20px",
                  ":hover": { backgroundColor: "#FFAE42" },
                }}
                startIcon={<DownloadingIcon />}
              >
                Request in progress
              </Button>
            )}

            {(fileReqStatus === "SUCCESS" ||
              getLatestSnapshotStatus === "SUCCESS") &&
              !callToUpdateSnapshot && (
                <Button
                  variant="contained"
                  className="button"
                  sx={{
                    backgroundColor: "green",
                    color: "white",
                    margin: "0 20px",
                  }}
                  onClick={handleDownloadfilereq}
                  startIcon={<DownloadingIcon />}
                >
                  File ready for download
                </Button>
              )}

            {(getLatestSnapshotStatus === "FAILED" ||
              fileReqStatus === "FAILED") &&
              getLatestSnapshotStatus !== "SUCCESS" &&
              fileReqStatus !== "CREATED" &&
              fileReqStatus !== "PROCESSING" &&
              fileReqStatus !== "SUCCESS" && (
                <Button
                  variant="contained"
                  color="inherit"
                  className="button"
                  sx={{ margin: "0 20px" }}
                  disabled
                  startIcon={<DownloadingIcon />}
                >
                  No File Requested
                </Button>
              )}
          </Box>
        </div>
        {enableEpoch && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              margin: "0 0",
              alignItems: "end",
              paddingRight: "20px",
              "& p": {
                margin: 0,
              },
            }}
          >
            <div>
              <p> Requested at {convertEpochToDateTime(epochTime)}</p>
            </div>
          </Box>
        )}

        <Dialog open={successfulUpdate} onClose={handleDialogClose}>
          <DialogTitle
            id="dialog-title"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "24px 0 24px 24px",
            }}
          >
            {"Upload Successful"}
            <Button
              startIcon={<CloseRoundedIcon />}
              onClick={handleDialogClose}
              sx={{ color: "black", padding: "0" }}
            ></Button>
          </DialogTitle>
          <DialogContent sx={{ padding: "10px 25px" }}>
            <DialogContentText
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon />
            </DialogContentText>
            <DialogContentText
              id="description"
              sx={{
                padding: " 25px 85px",
                fontSize: "14px",
                display: "flex",
                alignContent: "center",
                margin: "auto",
              }}
            >
              Your inventory file upload request has been placed. You can track
              the request ID {sessionUssid} on the Inventory update screen
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              onClick={handleDialogClose}
              sx={{
                marginBottom: "16px",
                backgroundcolor: "primary",
                color: "white",
              }}
              variant="contained"
            >
              Ok! Great
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            fontFamily: "Roboto",
            fontWeight: 600,
            fontSize: "14px",
            color: "#323A4F",
            paddingLeft: "10px",
            "& p": {
              marginTop: "5px",
            },
          }}
        >
          <p>Track Upload Request</p>
        </Box>
        <TableContainer sx={{ marginBottom: "40px" }}>
          <Table sx={{}} aria-label="trackuploadtable">
            <TableHead sx={{ backgroundColor: "#EEF2F6" }}>
              <TableRow
                sx={{ width: "50px", fontSize: "14px", padding: "16px" }}
              >
                {headersForTrackUploadTable.map(
                  (
                    item: { name: string; sx?: { padding: string } },
                    index: number
                  ) => (
                    <TableCell key={index} sx={item.sx} className="tablecell">
                      {item.name}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {validationData.map((item, index) => (
                <TableRow
                  sx={{ width: "70px", fontSize: "14px", padding: "16px" }}
                  key={index}
                >
                  <TableCell className="tablecell">
                    {item.sessionUssid}
                  </TableCell>
                  <TableCell className="tablecell">{item.createdAt}</TableCell>
                  <TableCell className="tablecell">{item.getStatus}</TableCell>
                  <TableCell className="tablecell">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDownload}
                      disabled={item.getStatus !== "FAILED"}
                    >
                      Validation File
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer sx={{ maxHeight: "650px" }}>
          <Table
            sx={{ minWidth: "650px", marginBottom: "20px" }}
            aria-label="simple table"
          >
            <TableHead sx={{ backgroundColor: "#EEF2F6" }}>
              <TableRow
                sx={{ width: "50px", fontSize: "14px", padding: "16px" }}
              >
                {headers.map((item: { name: string }, index: number) => (
                  <TableCell
                    key={index}
                    sx={{ padding: "10px 70px 10px 24px" }}
                    className="tablecell"
                  >
                    {item.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {lots?.map((item: ILot, index: number) => (
                <TableRow
                  sx={{ width: "70px", fontSize: "14px", padding: "16px" }}
                  key={index}
                >
                  {tableCellData.map(
                    (cell: { key: string }, cellIndex: number) => {
                      const cellValue = item[cell.key as keyof ILot];
                      const cellContent =
                        cellValue != null ? cellValue.toString() : "";
                      return (
                        <Tooltip title={cellContent} key={cellIndex}>
                          <TableCell className="tableCell" title={cellContent}>
                            {cell.key === "productName"
                              ? cellContent.substring(0, 35)
                              : cellContent.substring(0, 25)}
                          </TableCell>
                        </Tooltip>
                      );
                    }
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default TableComponent;
