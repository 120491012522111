import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthLayout from "../components/AuthLayout";
import Login from "../screens/Login";
import Layout from "../components/Layout";
import LandingPage from "../screens/LandingPage";
import PrintInvoice from "../screens/PrintInvoice/PrintInvoice";
import RequireAuth from "./RequireAuth";
import PendingOrder from "../screens/HomePage/PendingOrder";
import CancledOrders from "../screens/CancledPage";
import BulkPrice from "../screens/BulkPriceUpdate";
import PrescriptionPending from "../screens/PrescriptionPending";
import Inventory from "../screens/Inventory";

const NavigationRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <RequireAuth>
                <p>nothing here</p>
              </RequireAuth>
            }
          />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route
            path="/"
            element={
              <RequireAuth>
                <LandingPage />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/home"
            element={
              <RequireAuth>
                <PendingOrder />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/prescriptionPending"
            element={
              <RequireAuth>
                <PrescriptionPending />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/prescriptionPendingView"
            element={
              <RequireAuth>
                <PrescriptionPending showActions={false} />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/cancelled"
            element={
              <RequireAuth>
                <CancledOrders />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/invoice"
            element={
              <RequireAuth>
                <PrintInvoice />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/inventory"
            element={
              <RequireAuth>
                <Inventory />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard/BulkPrice"
            element={
              <RequireAuth>
                <BulkPrice />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default NavigationRoutes;
