import Box from "@mui/material/Box";
import theme from "../../theme";
import * as React from "react";
import { hasChildren } from "../../utils/sidePannel";
import { useEffect, useState } from "react";
import {
  CSSObject,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArticleIcon from "@mui/icons-material/Article";
import { Link, useLocation } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { useAnim } from "../../context/AnimationContext/hooks";

const CustomizedListItemIcon = styled(ListItemIcon)`
  :hover {
    color: #2e8b57;
  }
  padding-right: 20px;
  min-width: 0px;
`;
const drawerWidth = window.innerWidth * 0.18;

const openedMixin = (): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: "cubic-bezier(0.4, 0, 0.6, 1);",
    duration: 420,
  }),
  overflowX: "hidden",
});

const closedMixin = (): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 80,
});
const getStyles = (isDrawerOpen?: boolean) => ({
  openListItem: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
  },
  closeListItem: {
    display: "flex",
    marginBottom: 1,
    padding: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
  },
  listItemText: {
    color: theme.palette.text.primary,
    fontSize: "15px",
    padding: 0,
    margin: 0,
  },
  listIcon: {
    minWidth: 0,
  },
  link: {
    textDecoration: "none",
    fontSize: "15px",
  },
  paperProps: {
    position: "absolute",
    backgroundColor: "transparent",
    elevation: 0,
  },
  muiDrawer: {
    pointerEvents: "none",
    ...(isDrawerOpen ? openedMixin() : closedMixin()),
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      elevation: 0,
      shadowColor: "transparent",
      boxSizing: "border-box",
      borderRight: 0,
      ...(isDrawerOpen ? openedMixin() : closedMixin()),
    },
  },
  headerHeight: {
    height: window.innerHeight * 0.11,
  },
  drawerHeight: {
    height: "100%",
    pl: 2,
    pr: 2,
    pointerEvents: "all",
  },
});
const MenuItem = ({ item, main }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} main={main} />;
};

const MultiLevel = ({ item, main }: any) => {
  const { animState } = useAnim();
  const styles = getStyles();

  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (main == 0) {
      setOpen(true);
    }
  }, [main]);

  if (animState?.isSideBarOpen && animState?.isShowSideBarContents) {
    return (
      <>
        <ListItem
          onClick={handleClick}
          sx={{
            ...(open && styles.openListItem),
          }}
        >
          <CustomizedListItemIcon>{item.icon}</CustomizedListItemIcon>

          <ListItemText primary={item.title} sx={styles.listItemText} />
          {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children?.map((child: any, key: any) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <ListItem
      onClick={handleClick}
      sx={[
        styles.closeListItem,
        {
          ...(open && styles.openListItem),
        },
      ]}
    >
      <ListItemIcon sx={styles.listIcon}>{item.icon}</ListItemIcon>
    </ListItem>
  );
};

const SingleLevel = ({ item }: any) => {
  const location = useLocation();
  const styles = getStyles();

  // @ts-ignore
  const { animState } = useAnim();
  if (!animState?.isSideBarOpen && !animState?.isShowSideBarContents) {
    return null;
  }
  return (
    <ListItem>
      <CustomizedListItemIcon>{item?.icon}</CustomizedListItemIcon>
      <ListItemText
        sx={{ fontSize: 13 }}
        secondary={
          <Link
            to={item.to}
            style={{
              color:
                location.pathname === item.to
                  ? theme.palette.primary.dark
                  : theme.palette.text.secondary,
              ...styles.link,
            }}
          >
            {item.title}
          </Link>
        }
      />
    </ListItem>
  );
};

const menuItems = [
  {
    icon: <ShoppingCartIcon />,
    title: "Order Processing",
    items: [
      {
        title: "View Pending Rx Order",
        to: "/dashboard/prescriptionPendingView",
      },
      {
        title: "Rx Pending Seller Verification",
        to: "/dashboard/prescriptionPending",
      },

      {
        title: "Pending Orders",
        to: "/dashboard/home",
      },
      {
        title: "Cancelled Orders",
        to: "/dashboard/cancelled",
      },
      {
        title: "Print Invoice & Label",
        to: "/dashboard/invoice",
      },
    ],
  },
  {
    icon: <ArticleIcon />,
    title: "Reports & Dashboards",
    items: [
      {
        title: "Inventory Availability Report",
      },
      {
        title: "Pricing Report",
      },
    ],
  },
  {
    icon: <ArticleIcon />,
    title: "Self - Serve",
    items: [
      {
        title: "Inventory Update",
        to: "/dashboard/inventory",
      },
      {
        title: "Price Update",
        to: "/dashboard/BulkPrice",
      },
    ],
  },
];

const Sidebar = () => {
  const anim = useAnim();
  const isOpen = anim.animState?.isSideBarOpen;
  const styles = getStyles(Boolean(isOpen));
  return (
    <MuiDrawer
      PaperProps={{
        sx: styles.paperProps,
      }}
      // @ts-ignore
      sx={styles.muiDrawer}
      variant="permanent"
      anchor="left"
      open={anim.animState?.isSideBarOpen}
    >
      <Box sx={styles.headerHeight}></Box>
      <Box sx={styles.drawerHeight}>
        {menuItems.map((item, key) => (
          <MenuItem key={key} main={key} item={item} />
        ))}
      </Box>
    </MuiDrawer>
  );
};

export default Sidebar;
