import React from "react";
import { IAnimState } from "./AnimProvider";

interface IAnimContextType {
  animState?: IAnimState;
  sideBarTimeOut: (isSideBarOpen: boolean) => void;

  toggleSideBar: () => void;
}
const AnimContext = React.createContext<IAnimContextType>(
  {} as IAnimContextType
);
export default AnimContext;
