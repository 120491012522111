import { Box } from "@mui/material";
import React, { useState } from "react";
//@ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const TimePickerCustom = ({ updateDate }: { updateDate: any }) => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  const [startTime, setStartTime] = useState(d);
  const [endTime, setEndTime] = useState(d);

  const timeAdder = (time: Date, oldDate: string) => {
    return new Date(
      new Date(oldDate).setHours(time.getHours(), time.getMinutes())
    );
  };

  const onChangeTime = (date: Date, isStart: boolean) => {
    if (isStart) {
      setStartTime(date);
      updateDate((oldDate: string[]) => [
        timeAdder(date, oldDate[0]),
        oldDate[1],
      ]);
    } else {
      setEndTime(date);
      updateDate((oldDate: string[]) => [
        oldDate[0],
        timeAdder(date, oldDate[1]),
      ]);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "155px",
        justifyContent: "space-between",
        paddingTop: "10px",
      }}
    >
      <DatePicker
        selected={startTime}
        onChange={(date: Date) => {
          onChangeTime(date, true);
        }}
        wrapperClassName="customDatePickerWidth"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
      {"-"}
      <DatePicker
        selected={endTime}
        onChange={(date: Date) => {
          onChangeTime(date, false);
        }}
        wrapperClassName="customDatePickerWidth"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
    </Box>
  );
};

export default TimePickerCustom;
