import { ConfigHelper } from "../utils/ConfigHelper";
import StorageUtils, { StorageKeys } from "../utils/StorageUtils";

export interface ICallBackRes {
  data: ArrayBuffer | undefined;
  isSucess: boolean;
  status?: number;
}
class BulkPriceFetchRepo {
  //API Definitions
  public ViewPricing = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    myHeaders.append("x-client-id", "seller-panel");
    myHeaders.append("Content-Type", "application/json");
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    const bodyData = JSON.stringify({
      sellerApobId: sellerApobId,
      pageNumber: filterParam.pageNumber,
      maxResults: filterParam.maxResults,
    });
    const requestOptions: object = {
      method: "POST",
      headers: myHeaders,
      body: bodyData,
      redirect: "follow",
    };
    return fetch(`${ConfigHelper.getBaseUrl()}/pricing/view`, requestOptions);
  };

  public createSession = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-client-id", "seller-panel");

    const bodyData = JSON.stringify({
      tenantId: sellerApobId,
      namespace: filterParam.namespace,
      uploadBy: filterParam.uploadBy,
      fileType: filterParam.fileType,
    });
    const requestOptions: object = {
      method: "POST",
      headers: myHeaders,
      body: bodyData,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/session`,
      requestOptions
    );
  };

  public uploadSession = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    const ussid: string = filterParam.sessionUssid ?? "";
    myHeaders.append("x-client-id", "seller-panel");
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    formData.append("file", filterParam.selectedFile, "[PROXY]");

    const requestOptions: object = {
      method: "PUT",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/session/${ussid}/file/1`,
      requestOptions
    );
  };

  public statusCut = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    myHeaders.append("x-client-id", "VIADUCT");
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    myHeaders.append("Content-Type", "application/json");
    const ussid: string | null = localStorage.getItem("session-ussid") ?? "";
    const requestOptions: object = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/request/ingestion/${ussid}/status/FAILED `,
      requestOptions
    );
  };

  public closeSession = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-client-id", "VIADUCT");
    const ussid: string | null = localStorage.getItem("session-ussid") ?? "";

    const requestOptions: object = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/session/${ussid}/close`,
      requestOptions
    );
  };

  public getStatus = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-client-id", "VIADUCT");
    const ussid: string = filterParam.ussid ?? "";
    const action: string = filterParam.action;
    const requestOptions: object = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/request/${action}/${ussid}/status`,
      requestOptions
    );
  };

  public getReport = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    myHeaders.append("x-client-id", "seller-panel");
    const ussid: string | null = localStorage.getItem("session-ussid") ?? "";
    const requestOptions: object = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/request/${ussid}/report`,
      requestOptions
    );
  };

  public getLatestRequest = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-client-id", "seller-panel");
    const sellerApobId: string | null =
      StorageUtils().getItem(StorageKeys.sellerApobId) || "";
    const namespace: string = filterParam.namespace;
    const action: string = filterParam.action;
    const requestOptions: object = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const url = `/bulk-processor/v1/request/${sellerApobId}/${namespace}/${action}/get-latest`;
    return fetch(ConfigHelper.getBaseUrl() + url, requestOptions);
  };

  public downloadSnapshot = async (filterParam: any): Promise<any> => {
    const myHeaders = new Headers();
    const sessionID: string | null = StorageUtils().getItem(
      StorageKeys.sessionId
    );
    const sellerApobId: string | null =
      StorageUtils().getItem(StorageKeys.sellerApobId) || "";
    if (sessionID !== null) {
      myHeaders.append("Session-Id", sessionID);
    }
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-client-id", "seller-panel");
    const requestOptions: object = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(
      `${ConfigHelper.getBaseUrl()}/bulk-processor/v1/${sellerApobId}/pricing/download-snapshot`,
      requestOptions
    );
  };
}

const bulkPriceFetchRepo = new BulkPriceFetchRepo();
export { bulkPriceFetchRepo as BulkPriceFetchRepo };
