import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      light: "#b1e38b",
      main: "#94d958",
      dark: "#65b618",
    },
    secondary: {
      dark: "#1065C1",
      light: "#8DB4E1",
      main: "#4F8BD0",
    },
    error: {
      main: "#eb0101",
    },
    background: {
      default: "rgb(238, 242, 246)",
    },
    text: {
      primary: "rgb(18, 25, 38)",
      secondary: "rgb(54, 65, 82)",
    },
  },
});

export default theme;
