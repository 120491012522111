import { BuildConfig, IBuildConfig } from "../buildConfig";

class ConfigHelper {
  private readonly _buildConfig: IBuildConfig;

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this._buildConfig = BuildConfig;
  }

  public getBaseUrl = (): string => this._buildConfig.baseUrl;

  get appVersion(): string {
    return this._buildConfig.appVersion;
  }
}

const configHelper = new ConfigHelper();
export { configHelper as ConfigHelper };
