export const headers = [
  { name: "Product ID" },
  { name: "Product name" },
  { name: "Seller SKU ID" },
  { name: "Batch ID" },
  { name: "MRP" },
  { name: "Expiry" },
  { name: "Mfg Date" },
  { name: "Available Qty" },
];
export const headersForTrackUploadTable = [
  { name: "Status ID", sx: { padding: "10px 0px 10px 200px" } },
  { name: "Upload Date", sx: { padding: "10px 70px 10px 40px" } },
  { name: "Validation Status", sx: { padding: "10px 70px 10px 0px" } },
  { name: "Actions", sx: { padding: "10px 24px" } },
];
export const tableCellData = [
  { key: "fkhProductId" },
  { key: "productName" },
  { key: "productId" },
  { key: "batchId" },
  { key: "mrp" },
  { key: "expiryDate" },
  { key: "mfgDate" },
  { key: "availableQty" },
];
