import * as React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import StorageUtils, { StorageKeys } from "../../utils/StorageUtils";
import { useAuth } from "../../context/AuthContext/hooks";

const styles = {
  container: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
};
const Layout = () => {
  const auth = useAuth();
  const setLogin = () => {
    const loggedIn = localStorage.getItem(StorageKeys.isLoggedIn);
    if (loggedIn == "true") {
      const sessionId = StorageUtils().getItem(StorageKeys.sessionId);
      const sellerApobId = StorageUtils().getItem(StorageKeys.sellerApobId);
      const sellerName = StorageUtils().getItem(StorageKeys.sellerName);

      auth.setUser({
        sessionId: String(sessionId),
        sellerApobId: String(sellerApobId),
        sellerName: String(sellerName),
      });
    }
  };

  useEffect(() => {
    setLogin();
  }, []);

  return (
    <div style={styles.container}>
      <Header />
      <Box display={"flex"}>
        <Sidebar />
        <Outlet />
      </Box>
    </div>
  );
};

export default Layout;
