import "./tablecomp.css";
import { Box, Button, Container, Dialog } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import React, { useState } from "react";

type VoidHandler = () => void;
interface IFileUploadProps {
  callToUpdateSnapshot: boolean;
  fileReqStatus: string;
  getLatestSnapshotStatus: string;
  handleClose: VoidHandler;
  handleDownloadfilereq: VoidHandler;
  handleUpdateInventory: VoidHandler;
  selectedFile: File | null;
  setSelectedFile: any;
}
const acceptAttribute = ".csv, .xlsx";
const FileUpload: React.FC<IFileUploadProps> = ({
  selectedFile,
  setSelectedFile,
  handleClose,
  handleUpdateInventory,
  handleDownloadfilereq,
  fileReqStatus,
  getLatestSnapshotStatus,
  callToUpdateSnapshot,
}) => {
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0);

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setUploadFile(true);
  };

  const handleUpload = () => {
    setUploadFile(true);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <Container key={key}>
      <input
        accept={acceptAttribute}
        id="file-upload"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <label htmlFor="file-upload">
        <Button
          component="span"
          variant="outlined"
          tabIndex={-1}
          startIcon={<FileUploadIcon />}
          sx={{
            border: "2.5px dashed #D4D4D4",
            minHeight: "268px",
            minWidth: "100%",
            fontSize: "18px",
            fontWeight: "400",
          }}
          color="inherit"
          onClick={handleUpload}
        >
          Upload Updated Inventory file
        </Button>
      </label>
      {selectedFile && (
        <Dialog open={uploadFile} onClose={handleClose}>
          <DialogTitle
            id="dialog-title"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "24px 0 24px 24px",
            }}
          >
            {"Upload Updated Inventory"}
            <Button
              startIcon={<CloseRoundedIcon />}
              className="cross"
              onClick={handleClose}
              sx={{ color: "black", padding: "0" }}
            ></Button>
          </DialogTitle>
          <DialogContent sx={{ padding: "10px 25px" }}>
            <input
              accept={acceptAttribute}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="file-upload">
              <Container
                component="span"
                tabIndex={-1}
                sx={{
                  display: "flex",
                  flexDirection: " column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2.5px dashed #D4D4D4",
                  height: "268px",
                  width: "100%",
                  fontSize: "20px",
                  fontWeight: "500",
                  padding: "100px",
                }}
                color="inherit"
              >
                <Box>{<FileUploadIcon />}</Box>
                {selectedFile ? selectedFile.name : "Upload a file"}
                <Button
                  onClick={handleRemoveFile}
                  color="error"
                  sx={{ padding: "20px 50 px" }}
                >
                  Remove Item
                </Button>
              </Container>
            </label>
            <DialogContentText
              id="description"
              sx={{
                padding: "15px",
                fontSize: "14px",
                display: "flex",
                alignContent: "center",
                margin: "auto",
              }}
            >
              To update inventory, make changes to the current inventory file
              and upload the updated version here.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
            {(fileReqStatus === "SUCCESS" ||
              getLatestSnapshotStatus === "SUCCESS") &&
              !callToUpdateSnapshot && (
                <Button
                  sx={{
                    marginBottom: "16px",
                  }}
                  onClick={handleDownloadfilereq}
                >
                  Download Current Inventory
                </Button>
              )}
            <Button
              onClick={handleUpdateInventory}
              sx={{
                marginBottom: "16px",
                backgroundcolor: "primary",
                color: "white",
              }}
              variant="contained"
            >
              Update Inventory
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default FileUpload;
